import {
  IPriorityLevelEnum,
  ITaskAssignment,
  ITaskStatusEnum,
  IUser,
} from '@tapestry/shared/graphql';
import { FC } from 'react';
import { TaskListItem } from './TaskListItem';
import { Maybe } from 'graphql/jsutils/Maybe';
import { ITaskOccurrenceManagerTypeWithoutId } from '../../types';

type Task = {
  id: string;
  title: string;
  owner: IUser;
  status: ITaskStatusEnum;
  require_resolution?: Maybe<boolean>;
  priority: IPriorityLevelEnum;
  assignments?: Maybe<Array<ITaskAssignment>>;
  due_date?: null | `${number}-${number}-${number}T00:00:00.0000Z`;
  occurrence_manager?: Maybe<ITaskOccurrenceManagerTypeWithoutId>;
};

type TaskListProps = {
  tasks: Task[];
};

const TaskList: FC<TaskListProps> = ({ tasks }) => {
  return tasks.map(
    ({
      id,
      title,
      assignments,
      status,
      owner,
      require_resolution,
      priority,
      due_date,
      occurrence_manager,
    }) => {
      const isMyTask = owner?.isMe ?? false;
      const requireResolution = require_resolution ?? false;
      const dueDate = due_date ?? null;
      const isRepeatedTask = occurrence_manager?.active ?? false;

      return (
        <TaskListItem
          key={id}
          id={id}
          title={title}
          status={status}
          isMyTask={isMyTask}
          assignments={assignments ?? []}
          requireResolution={requireResolution}
          priority={priority ?? IPriorityLevelEnum.Medium}
          dueDate={dueDate}
          isRepeatedTask={isRepeatedTask}
        />
      );
    }
  );
};

export { TaskList };
