import { FC } from 'react';
import { RepeatingRulesAction } from '../../reducer';
import { CheckboxGroup } from 'react-aria-components';
import { Checkbox } from '@tapestry/weave';
import { CALENDAR_WEEK_ZERO_IDX } from '@tapestry/shared/constants';

interface WeeklyOptionsProps {
  dispatch: React.Dispatch<RepeatingRulesAction>;
  weekdays: number[];
  disabled: boolean;
}

export const WEEKDAY_RADIO_ITEMS = CALENDAR_WEEK_ZERO_IDX.map((day, idx) => ({
  value: String(idx),
  label: day.charAt(0),
  ariaLabel: day,
}));

export const WeeklyOptions: FC<WeeklyOptionsProps> = ({
  dispatch,
  weekdays,
  disabled,
}) => {
  const handleToggleDays = (list: string[]) => {
    dispatch({
      type: 'WEEKDAY_CHANGE',
      payload: { weekday: list.map(Number) },
    });
  };

  return (
    <div className="text-gray-text block w-full sm:grid sm:grid-cols-4 sm:gap-x-4 lg:w-1/2">
      <span className="mb-1 inline-block sm:col-span-1 sm:mb-0">
        Repeat on:
      </span>

      <CheckboxGroup
        value={weekdays.map(String)}
        onChange={handleToggleDays}
        className="flex w-full items-center justify-around sm:col-span-3"
        aria-label="Choose week days"
        isDisabled={disabled}
      >
        {WEEKDAY_RADIO_ITEMS.map((weekday) => (
          <Checkbox
            key={weekday.value}
            value={weekday.value}
            aria-label={weekday.ariaLabel}
            render={() => (
              <span className="group-data-[selected=true]:bg-primary group-data-[selected=true]:border-primary border-gray-border ring-primary group-data-[pressed=true]:bg-primary-lighter text-gray-text flex size-8 shrink-0 cursor-pointer items-center justify-center rounded-full border bg-white text-sm capitalize transition-colors duration-300 group-data-[selected=true]:text-white group-data-[pressed=true]:ring-2">
                {weekday.label}
              </span>
            )}
          />
        ))}
      </CheckboxGroup>
    </div>
  );
};
