import { loadable } from '@tapestry/shared/lazy-load';

export const TaskResolutionModal = loadable(
  () => import('./TaskResolutionModal'),
  {
    chunkName: 'task-resolution-modal',
    ssr: false,
  }
);

export const ViewTaskResolutionModal = loadable(
  () => import('./ViewTaskResolutionModal'),
  {
    chunkName: 'view-task-resolution-modal',
    ssr: false,
  }
);

export type { TaskResolutionFormData } from './TaskResolution.types';
