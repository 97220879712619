import { ITaskOccurrenceFrequencyEnum } from '@tapestry/shared/graphql';
import { Maybe } from 'graphql/jsutils/Maybe';
import { dateTime } from '@tapestry/shared/utils';
import {
  DueDateRepeatState,
  repeatingRulesDefaultState,
} from '../components/DueDateRepeat/reducer';
import { ITaskOccurrenceManagerTypeWithoutId } from '../types';
import { MonthlyRepeatOptionKey } from '../components/DueDateRepeat/types';

// TODO: move this file into the DueDateRepeat component folder
// and write tests for it
type MappedTaskOccurenceManager = Omit<
  ITaskOccurrenceManagerTypeWithoutId,
  'end_date'
> & {
  end_date: string;
};

const DEFAULT_KEY = repeatingRulesDefaultState.selectedMonthlyRepeatOptionKey;
const ULTIMATE_WEEKDAY_OCCURENCE = 5;

const getMonthlyRepeatKey = (
  value: MappedTaskOccurenceManager
): MonthlyRepeatOptionKey => {
  const { frequency, rules } = value;
  const { byMonthDay, nthWeekday } = rules ?? {};

  if (frequency !== ITaskOccurrenceFrequencyEnum.Monthly) {
    return DEFAULT_KEY;
  }

  if (byMonthDay === -1) {
    return 'lastDayOfMonth';
  }

  const nthWeekdayValue = Number(nthWeekday?.[0]);

  if (nthWeekdayValue < ULTIMATE_WEEKDAY_OCCURENCE) {
    return 'nthWeekday';
  }

  if (nthWeekdayValue === ULTIMATE_WEEKDAY_OCCURENCE) {
    return 'lastWeekday';
  }

  return 'dayOfMonth';
};

export const parseRRulesToState = (
  value: Maybe<MappedTaskOccurenceManager>
): DueDateRepeatState | null => {
  if (!value) return null;

  const { end_date, active, ...APIRecurrency } = value;

  const state = {
    ...APIRecurrency,
    active: active ?? false,
    end_date: dateTime.format(end_date, 'YYYY-MM-DD'),
    selectedMonthlyRepeatOptionKey: getMonthlyRepeatKey(value),
  };

  return state;
};
