import { IUser } from '@tapestry/shared/graphql';
import { RenderableUser } from '../components/AssignToModal/UserListItem';

/**
 * first_name and last_name are not guaranteed to be defined, so we need to filter out the assignments that don't have them as the `UserListItem` component would render them as empty strings.
 */
export const filterValidUsers = (users: IUser[]) => {
  return users.filter(
    (user) => user.first_name || user.last_name
  ) as RenderableUser[];
};
