import { ITaskStatusEnum } from '@tapestry/shared/graphql';
import { TickIcon } from '@tapestry/shared/icons';
import { FC } from 'react';
import { twMerge } from 'tailwind-merge';
import Image from 'next/image';

export type AvatarGroupAvatarProps = {
  label: string;
  title?: string;
  imageUrl?: string;
  taskStatus?: string;
};

const IMAGE_SIZE = 32;

const AvatarGroupAvatar: FC<AvatarGroupAvatarProps> = ({
  taskStatus,
  label,
  imageUrl,
  title,
}) => {
  const caption = title ?? label;
  const isCompletedTask = taskStatus === ITaskStatusEnum.Completed;

  const className = twMerge(
    'relative block size-8 rounded-full ring-2 bg-gray-lightest overflow-hidden',
    isCompletedTask ? 'ring-green' : 'ring-gray-lightest'
  );

  return (
    <figure className="cursor relative isolate z-10 hover:z-20" title={caption}>
      {imageUrl ? (
        <Image
          src={imageUrl}
          alt={label}
          className={className}
          width={IMAGE_SIZE}
          height={IMAGE_SIZE}
        />
      ) : (
        <span className={twMerge(className, 'bg-cerulean-light leading-loose')}>
          {label}
        </span>
      )}
      <figcaption className="sr-only">{caption}</figcaption>
      {isCompletedTask ? (
        <span className="bg-green absolute bottom-[-7px] right-[-2px] z-10 block size-4 rounded-full p-1 text-white">
          <TickIcon light fillColor="currentColor" />
        </span>
      ) : null}
    </figure>
  );
};

export { AvatarGroupAvatar };
