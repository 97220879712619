import { GET_TASKS_DEFAULT_VARIABLES } from '../../constants';
import { TaskListItem } from './TaskListItem';

const deafultTaskListSize = GET_TASKS_DEFAULT_VARIABLES.limit;

const TaskListLoadingState = () => {
  return Array(deafultTaskListSize)
    .fill(null)
    .map((_, index) => <TaskListItem key={index} isLoading />);
};

export { TaskListLoadingState };
