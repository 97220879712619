import { dateTime } from '@tapestry/shared/utils';
import * as yup from 'yup';

const MIN_DUE_DATE = dateTime.now().toDate();
const FORM_DATE_PICKER_RETURN_VALUE = 'YYYY-MM-DDTHH:mm:ss.SSSZ';

const isValidDateFormat = (value) => {
  return (
    value === null ||
    dateTime.parse(value, FORM_DATE_PICKER_RETURN_VALUE).isValid()
  );
};

const isNotPastDate = (value) => {
  if (value === null) return true;

  const dueDate = dateTime.parse(value);

  return dueDate.isSame(MIN_DUE_DATE) || dueDate.isAfter(MIN_DUE_DATE);
};

const dateFormatTest = yup
  .string()
  .nullable()
  .test(
    'is-valid-format',
    'Invalid date format. Please use one of DD/MM/YYYY, DDMMYYYY',
    isValidDateFormat
  );

export const schema = yup.object({
  title: yup
    .string()
    .required('A task name is required')
    .min(2, 'Task name is too short')
    .max(255, 'Task name cannot be longer than 255 characters'),
  due_date: dateFormatTest.test(
    'min-date',
    'Due date cannot be in the past, please update',
    isNotPastDate
  ),

  occurrence_manager: yup
    .object({
      active: yup.boolean(),
      end_date: dateFormatTest.when('active', {
        is: true,
        then: (schema) => schema.required('Required when Repeat is active'),
        otherwise: (schema) => schema.nullable(),
      }),
    })
    .nullable(),
});
