import { useModal } from '@tapestry/shared/hooks';
import { TickIcon } from '@tapestry/shared/icons';
import { FC, useMemo } from 'react';
import { twMerge } from 'tailwind-merge';
import {
  TaskResolutionFormData,
  TaskResolutionModal,
} from '../TaskResolutionModal';
import {
  GetTaskDocument,
  ITaskAssignment,
  ITaskStatusEnum,
  IUpdateTaskAssigneeStatus,
  IUpdateTaskStatus,
  useUpdateTaskAssigneeStatus,
  useUpdateTaskStatus,
} from '@tapestry/shared/graphql';
import { transformAttachmentListItemsToTaskAttachments } from '../../utils/attachment';
import { useTasksPagesErrorToastsAndRedirect } from '../../hooks/use-edit-page-error-toasts-and-redirects';

type TaskStatusToggleButtonProps = {
  taskId: string;
  isOwner: boolean;
  requireResolution: boolean;
  taskStatus: ITaskStatusEnum;
  currentUserAssignment?: ITaskAssignment;
  className?: string;
  variant?: 'icon' | 'text';
  onTaskStatusUpdateComplete?: (data: IUpdateTaskStatus) => void;
  onTaskAssigmentStatusUpdateComplete?: (
    data: IUpdateTaskAssigneeStatus
  ) => void;
};

const TaskStatusToggleButton: FC<TaskStatusToggleButtonProps> = ({
  isOwner,
  taskStatus,
  requireResolution,
  taskId,
  currentUserAssignment,
  className,
  variant = 'icon',
  onTaskStatusUpdateComplete,
  onTaskAssigmentStatusUpdateComplete,
}) => {
  const taskResolutionModalState = useModal();
  const callbackSequences = useTasksPagesErrorToastsAndRedirect();

  const [updateTaskStatus] = useUpdateTaskStatus({
    onError: callbackSequences.onUpdateTaskStatusError,
    refetchQueries: [{ query: GetTaskDocument, variables: { taskId } }],
    onCompleted: onTaskStatusUpdateComplete,
  });
  const [updateTaskAssignmentStatus] = useUpdateTaskAssigneeStatus({
    onError: callbackSequences.onUpdateTaskStatusError,
    onCompleted: onTaskAssigmentStatusUpdateComplete,
  });

  const isCompleted = useMemo(() => {
    const _taskStatus = !isOwner ? currentUserAssignment?.status : taskStatus;

    return _taskStatus === ITaskStatusEnum.Completed;
  }, [currentUserAssignment?.status, isOwner, taskStatus]);

  const handleTaskResolutionConfirm = (resolution?: TaskResolutionFormData) => {
    updateTaskAssignmentStatus({
      variables: {
        taskId: taskId,
        status: ITaskStatusEnum.Completed,
        resolution: {
          summary: resolution?.summary,
          files: transformAttachmentListItemsToTaskAttachments(
            resolution?.files ?? []
          ),
        },
      },
    });

    if (isOwner && resolution?.shouldUpdateMainStatus) {
      updateTaskStatus({
        variables: {
          taskId: taskId,
          status: ITaskStatusEnum.Completed,
        },
      });
    }
  };

  const handleButtonClick = () => {
    if (currentUserAssignment) {
      if (
        !isCompleted &&
        requireResolution &&
        currentUserAssignment.status !== ITaskStatusEnum.Completed
      ) {
        return taskResolutionModalState.open();
      }

      updateTaskAssignmentStatus({
        variables: {
          taskId,
          assigneeId: currentUserAssignment?.assignee_id ?? null,
          status: isCompleted
            ? ITaskStatusEnum.Pending
            : ITaskStatusEnum.Completed,
          resolution: null,
        },
      });
    }

    if (isOwner) {
      updateTaskStatus({
        variables: {
          taskId: taskId,
          status: isCompleted
            ? ITaskStatusEnum.Pending
            : ITaskStatusEnum.Completed,
        },
      });
    }
  };

  const TITLE = `Mark ${isCompleted ? 'pending' : 'complete'}`;

  return (
    <>
      {variant === 'icon' ? (
        <button
          className={twMerge(
            'border-gray-border z-10 inline-flex size-10 flex-shrink-0 cursor-pointer items-center justify-center rounded-full border p-1 transition-colors duration-150 sm:size-12 sm:p-2',
            'group-[.tasks-list-visualisation]:size-8 group-[.tasks-list-visualisation]:p-1',
            isCompleted
              ? 'bg-green text-white'
              : 'text-gray-text hover:bg-green-light bg-white hover:text-white',
            className
          )}
          type="button"
          onClick={handleButtonClick}
          title={TITLE}
          onKeyUp={({ key }) => {
            if (key === 'Enter') {
              handleButtonClick();
            }
          }}
        >
          <TickIcon light fillColor="currentColor" />
          <p className="sr-only">Mark {isCompleted ? 'pending' : 'complete'}</p>
        </button>
      ) : null}

      {variant === 'text' ? (
        <button
          className={twMerge(
            'border-gray-border hover:ring-gray-hover focus:ring-gray-hover group flex items-center overflow-y-hidden rounded-lg border p-2 text-gray-900 transition-colors duration-150 hover:ring-1 focus:outline-none focus:ring-1',
            className
          )}
          type="button"
          onClick={handleButtonClick}
          title={TITLE}
          onKeyUp={({ key }) => {
            if (key === 'Enter') {
              handleButtonClick();
            }
          }}
        >
          <TickIcon
            light
            fillColor="currentColor"
            className={twMerge(
              'border-gray-border size-6 flex-shrink-0 rounded-full border p-1 transition-colors duration-150',
              isCompleted
                ? 'bg-green text-white'
                : 'group-hover:bg-green-light group-focus:bg-green-light'
            )}
          />

          {/* Sliding text */}
          <span
            data-hover="Mark Pending"
            className={twMerge(
              'relative inline-block w-32 overflow-hidden text-sm font-medium duration-200  ease-out',
              'before:absolute before:left-0 before:w-full before:-translate-y-full before:transform before:whitespace-nowrap before:opacity-0 before:transition before:duration-200 before:ease-out before:content-[attr(data-hover)]',
              isCompleted &&
                'before:group-hover:translate-y-0 before:group-hover:opacity-100 before:group-focus:translate-y-0 before:group-focus:opacity-100'
            )}
          >
            <span
              className={twMerge(
                'inline-block transform whitespace-nowrap opacity-100 transition duration-200 ease-out',
                isCompleted &&
                  'group-hover:translate-y-full group-hover:opacity-0 group-focus:translate-y-full group-focus:opacity-0'
              )}
            >
              {isCompleted ? 'Completed' : 'Mark complete'}
            </span>
          </span>
        </button>
      ) : null}

      {taskResolutionModalState.isOpen && (
        <TaskResolutionModal
          isOwner={isOwner}
          modalState={taskResolutionModalState}
          onConfirm={handleTaskResolutionConfirm}
        />
      )}
    </>
  );
};

export { TaskStatusToggleButton };
