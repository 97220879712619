import { ROUTE_PATHS, SHORTCUT } from '@tapestry/shared/constants';
import { useGetTasks } from '../hooks/use-get-tasks';
import { useRouter } from 'next/router';
import {
  AppletTemplateFrame,
  PaginationFooter,
  WidgetBar,
  WidgetBarButton,
} from '@tapestry/shared/components';
import { Stack } from '@tapestry/weave';
import isEmpty from 'lodash/isEmpty';
import {
  TasksListEmptyState,
  TaskListLoadingState,
  TaskList,
} from '../components/TasksList';
import { useKeyboardShortcut, useProfile } from '@tapestry/shared/hooks';
import { IGetTaskSortEnum } from '@tapestry/shared/graphql';
import { TasksListPageHeader } from '../components/TaskListPageHeader';
import { FC } from 'react';
import {
  useQueryParam,
  NumberParam,
  withDefault,
  JsonParam,
  TasksSortParam,
  mapObjectFilterValueToids,
} from '@tapestry/shared/utils';
import { UserSetting } from '@tapestry/types';
import { TasksAppletFilters } from '../types';
import { GET_TASKS_DEFAULT_VARIABLES, POLLING_INTERVAL } from '../constants';
import { PlusIcon } from '@tapestry/shared/icons';

const TasksListPage: FC = () => {
  const [profile] = useProfile();
  const router = useRouter();
  useKeyboardShortcut({
    [SHORTCUT.TASKS.CREATE_NEW_TASK]: () => {
      router.push(ROUTE_PATHS.createNewTask);
    },
  });
  const [page, setPage] = useQueryParam(
    'pagination',
    withDefault(NumberParam, GET_TASKS_DEFAULT_VARIABLES.page)
  );
  const [sortBy, setSortByParam] = useQueryParam(
    'sort',
    withDefault(TasksSortParam, IGetTaskSortEnum.Default)
  );
  const [activeFilters, setActiveFilters] = useQueryParam<
    TasksAppletFilters | undefined
  >(
    'filters',
    withDefault(
      JsonParam,
      profile?.user_settings[UserSetting.applet_tasks_filters] || undefined
    )
  );
  const searchParam = (router.query.searchTerm ?? '').toString();

  const {
    data: { tasks, pagination },
    isLoadingTasks,
    error,
    networkStatus,
    fetchMore: fetchMoreNewPaginatedThreads,
  } = useGetTasks({
    pollInterval: POLLING_INTERVAL,
    skipPollAttempt: () => window.document.hidden,
    variables: {
      ...GET_TASKS_DEFAULT_VARIABLES,
      search: searchParam,
      page,
      sortBy,
      filters: mapObjectFilterValueToids(activeFilters),
    },
  });

  const isFirstLoading = networkStatus === 1;
  const isLastPage = pagination?.currentPage === pagination?.totalPages;

  const handleOnPrevious = (providedOffest?: number) => {
    const previousOffset = providedOffest ?? page - 1;

    setPage(previousOffset);
  };

  const handleOnNextFetching = (providedOffest?: number) => {
    if (!fetchMoreNewPaginatedThreads) return;

    const newOffest = providedOffest ?? page + 1;

    fetchMoreNewPaginatedThreads({
      variables: {
        offset: newOffest,
      },
    }).then(() => setPage(newOffest));
  };

  return (
    <AppletTemplateFrame
      title="Tasks"
      filterBar={
        <TasksListPageHeader
          handleSetSort={setSortByParam}
          activeFilters={activeFilters}
          handleSetActiveFilters={setActiveFilters}
        />
      }
    >
      <Stack hasTopMargin>
        {isEmpty(tasks) && !isLoadingTasks && !error ? (
          <TasksListEmptyState searchParam={searchParam} />
        ) : null}

        <ul className="@container flex w-full flex-col gap-2">
          {isEmpty(tasks) && isLoadingTasks && !error ? (
            <TaskListLoadingState />
          ) : null}

          {!isEmpty(tasks) && !isLoadingTasks && !error ? (
            <TaskList tasks={tasks} />
          ) : null}
        </ul>

        <Stack>
          {!isEmpty(pagination) &&
            !isEmpty(tasks) &&
            pagination?.totalPages > 1 && (
              <PaginationFooter
                className=""
                isLoading={isFirstLoading}
                currentPage={pagination?.currentPage}
                lastPage={pagination?.totalPages}
                isLastPage={isLastPage}
                showBoundaryPages
                boundaryPagesAmount={2}
                showFirstAndLastPage
                onPrev={handleOnPrevious}
                onNext={handleOnNextFetching}
                onPrevWithPageNumber={handleOnPrevious}
                onNextWithPageNumber={handleOnNextFetching}
              />
            )}
        </Stack>
      </Stack>

      <WidgetBar>
        <WidgetBarButton
          title="Create a new task"
          action="create-new-task"
          onPress={() => router.push(ROUTE_PATHS.createNewTask)}
          variant="primary"
          icon={<PlusIcon light fillColor="currentColor" />}
        />
      </WidgetBar>
    </AppletTemplateFrame>
  );
};

export { TasksListPage };
