import {
  Field,
  Label,
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
} from '@headlessui/react';
import { DueDateRepeatState, RepeatingRulesAction } from '../../reducer';
import { FC } from 'react';
import { ITaskOccurrenceFrequencyEnum } from '@tapestry/shared/graphql';

interface IntervalSelectionProps {
  frequency: DueDateRepeatState['frequency'];
  dispatch: React.Dispatch<RepeatingRulesAction>;
  disabled: boolean;
}

const FREQUENCY_OPTION = [
  ITaskOccurrenceFrequencyEnum.Daily,
  ITaskOccurrenceFrequencyEnum.Weekly,
  ITaskOccurrenceFrequencyEnum.Monthly,
  ITaskOccurrenceFrequencyEnum.Yearly,
];

export const IntervalSelection: FC<IntervalSelectionProps> = ({
  frequency,
  dispatch,
  disabled,
}) => {
  return (
    <div className="sm:grid sm:grid-cols-4 sm:gap-x-4 lg:w-1/2">
      <span className="text-gray-text mb-1 flex shrink-0 items-center sm:col-span-1 sm:mb-0">
        Repeats:
      </span>

      <Field className="sm:col-span-3">
        <Label className="sr-only">Repeat Frequency</Label>
        <Listbox
          value={frequency}
          onChange={(payload) =>
            dispatch({ type: 'FREQUENCY_CHANGE', payload })
          }
          disabled={disabled}
        >
          <ListboxButton className="border-gray-lightest focus:border-orange-hank relative flex w-full cursor-default items-center justify-between truncate rounded-md border bg-white py-3 pl-3 pr-3 text-left text-sm capitalize focus:outline-none disabled:cursor-auto sm:text-xl">
            {frequency}
          </ListboxButton>
          <ListboxOptions
            anchor="bottom"
            className="z-dropdown xs:w-64 w-32 rounded-md bg-white p-2 shadow-lg"
          >
            {FREQUENCY_OPTION.map((frequency) => (
              <ListboxOption
                key={frequency}
                value={frequency}
                className="data-[selected]:text-primary hover:bg-gray-hover cursor-pointer rounded-sm px-2 py-1 capitalize"
              >
                {frequency}
              </ListboxOption>
            ))}
          </ListboxOptions>
        </Listbox>
      </Field>
    </div>
  );
};
