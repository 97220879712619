import { Circle } from '@tapestry/weave';
import { ReactNode } from 'react';

/**
 * wrapper to render an icon
 */
export const renderTaskFormFieldIcon = (icon: ReactNode, isPhone: boolean) => {
  if (!isPhone) return null;

  return (
    <Circle size="size-6 p-1.5" className="bg-pink">
      {icon}
    </Circle>
  );
};
