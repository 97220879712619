import { FC, useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import { TagListItem } from './TagListItem';
import { Tag } from '../../types';

type TagListProps = {
  tags: Tag[];
  currentlyAssignedTags: Tag[];
  handleToggleTag: (tag: Tag) => void;
} & (
  | {
      fetchMoreThreshold: number;
      onFetchMore: () => void;
    }
  | {
      fetchMoreThreshold?: never;
      onFetchMore?: never;
    }
);

const getUnassignedTags = (
  tags: Tag[],
  currentlyAssignedTags: TagListProps['currentlyAssignedTags']
) => {
  return tags.filter((tag) => {
    return !currentlyAssignedTags.some((_tag) => _tag.id === tag.id);
  });
};

const TagList: FC<TagListProps> = ({
  tags,
  currentlyAssignedTags,
  fetchMoreThreshold,
  onFetchMore,
  handleToggleTag,
}) => {
  const { ref, entry } = useInView({
    threshold: 1,
  });

  useEffect(
    function fetchMoreItemWhenReachingThreshold() {
      if (entry?.isIntersecting && onFetchMore) {
        onFetchMore();
      }
    },
    [entry?.isIntersecting, onFetchMore]
  );

  return (
    <ul className="no-scrollbar flex max-h-[400px] flex-col gap-2 overflow-hidden overflow-y-auto">
      {currentlyAssignedTags.map((tag) => {
        return (
          <li key={tag.id}>
            <TagListItem tag={tag} handleToggle={handleToggleTag} isSelected />
          </li>
        );
      })}

      {getUnassignedTags(tags, currentlyAssignedTags).map((tag, index) => {
        const inViewRef =
          fetchMoreThreshold && tags.length - fetchMoreThreshold === index
            ? ref
            : null;

        return (
          <li key={tag.id} ref={inViewRef}>
            <TagListItem
              tag={tag}
              handleToggle={handleToggleTag}
              isSelected={false}
            />
          </li>
        );
      })}
    </ul>
  );
};

export { TagList };
