import { extractDate } from '@tapestry/shared/utils';
import { TasksAppletFilters } from '../../types';
import { TasksAppletFiltersForm } from './TasksAppletFiltersModal';
import { DateString } from '@tapestry/weave';

const transformFieldDueDateToAPIVar = (dueDate: DateString | null) => {
  return dueDate ? `${dueDate}T00:00:00.000000Z` : null;
};

export const mapDueDateFormToFilter = (
  dueDate: TasksAppletFiltersForm['due_date']
) => {
  const isNotSet = !dueDate || (!dueDate.start && !dueDate.end);
  if (isNotSet) return undefined;

  const due_date = Object.entries(dueDate).reduce((acc, [when, date]) => {
    if (!date) return acc;

    acc[when] = transformFieldDueDateToAPIVar(date);

    return acc;
  }, {});

  return { due_date };
};

export const mapDueDateFilterToForm = (
  dueDateFilter: TasksAppletFilters['due_date']
) => {
  if (!dueDateFilter) return undefined;

  const due_date = Object.entries(dueDateFilter).reduce((acc, [when, date]) => {
    if (!date) return acc;

    acc[when] = extractDate(date);

    return acc;
  }, {});

  return { due_date };
};
