import { useGetTasks as useGetTasksAPI } from '@tapestry/shared/graphql';

const useGetTasks = (...args: Parameters<typeof useGetTasksAPI>) => {
  const { data, loading, ...rest } = useGetTasksAPI(...args);

  const tasks = data?.getTasks?.data ?? [];

  return {
    ...rest,
    data: {
      tasks,
      pagination: data?.getTasks?.pagination,
    },
    isLoadingTasks: loading,
  };
};

export { useGetTasks };
