import { IGetTaskSortEnum } from '@tapestry/shared/graphql';

export const POLLING_INTERVAL = 10000;

export const GET_TASKS_DEFAULT_VARIABLES = {
  page: 1,
  limit: 10,
  taskType: 'retail_insight',
  sortBy: IGetTaskSortEnum.Default,
  filters: {},
  search: '',
};
