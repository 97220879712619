import { CALENDAR_WEEK_ZERO_IDX } from '@tapestry/shared/constants';
import { IsoString } from '@tapestry/types';
import capitalize from 'lodash/capitalize';
import { MonthlyRepeatOption } from '../../../types';
import { getDueDateMeta } from '../../../../../utils/get-due-date-meta-data';
import { isWithinTheLastWeek } from '../../../../../utils/is-within-the-last-week-of-month';

const ORDINAL_NUMBERS_WORD = [
  'zero',
  'first',
  'second',
  'third',
  'fourth',
  'fifth',
] as const;

/**
 * Makes dropdown menu option depending on the due date value
 */
export const getMonthlyRepeatOptions = (dueDate: IsoString | null) => {
  if (!dueDate) return [];

  const { dueWeekDay, nthWeekday, isLastDayOfMonth, ordinalNumberDay } =
    getDueDateMeta(dueDate);
  const isLastIteration = isWithinTheLastWeek(dueDate);

  const ordinal = ORDINAL_NUMBERS_WORD[nthWeekday];
  const englishDayOfWeek = CALENDAR_WEEK_ZERO_IDX[dueWeekDay];

  let baseOption: MonthlyRepeatOption[] = [
    {
      key: 'dayOfMonth',
      label: `Monthly on the ${ordinalNumberDay}`,
      value: null, // this is the default behaviour
    },
  ];

  if (!isLastIteration) {
    baseOption.push({
      key: 'nthWeekday',
      label: `Monthly on the ${ordinal} ${capitalize(englishDayOfWeek)}`,
      value: {
        weekday: [dueWeekDay],
        nthWeekday: [nthWeekday] as [number],
      },
    });
  }

  if (isLastDayOfMonth) {
    baseOption.push({
      key: 'lastDayOfMonth',
      label: `Monthly on the last day of the month`,
      value: {
        byMonthDay: -1,
      },
    });
  }

  if (nthWeekday >= 4) {
    baseOption.push({
      key: 'lastWeekday',
      label: `Monthly on the last ${capitalize(englishDayOfWeek)}`,
      value: {
        weekday: [dueWeekDay],
        nthWeekday: [5] as [number],
      },
    });
  }

  return baseOption;
};
