import {
  GetTaskDocument,
  ITaskAssignment,
  ITaskStatusEnum,
  IUser,
  useSearchUsers,
  useSendTaskAssignmentReminderEmail,
  useUpdateTaskAssigneeStatus,
  useUpdateTaskAssignments,
  useUpdateTaskStatus,
} from '@tapestry/shared/graphql';
import {
  Button,
  Checkbox,
  FormInputBase,
  useAppMediaQuery,
} from '@tapestry/weave';
import isEmpty from 'lodash/isEmpty';
import { FC, useEffect, useMemo, useState } from 'react';
import { RenderableUser } from '../AssignToModal/UserListItem';
import { useModal } from '@tapestry/shared/hooks';
import { loadable } from '@tapestry/shared/lazy-load';
import { AssigneeListItem } from './AssigneeListItem';
import { useToast } from '@tapestry/shared/client';
import {
  alphabeticallySort,
  getCurrentAssignees,
  getValidAssigments,
} from './assignee-field-utils';
import Skeleton from 'react-loading-skeleton';
import {
  TaskResolutionFormData,
  TaskResolutionModal,
} from '../TaskResolutionModal';
import { transformAttachmentListItemsToTaskAttachments } from '../../utils/attachment';
import { useTasksPagesErrorToastsAndRedirect } from '../../hooks/use-edit-page-error-toasts-and-redirects';
import { StaffIcon } from '@tapestry/shared/icons';
import { renderTaskFormFieldIcon } from '../../utils/render-form-filed-icon';

const AssignStaffModal = loadable(
  () => import('../AssignToModal/AssignToModal'),
  {
    chunkName: 'add-staff-modal',
    ssr: false,
  }
);

interface AssigneesFieldProps {
  assignments: ITaskAssignment[];
  canEdit: boolean;
  taskId: string;
  requireResolution: boolean;
  onRequireResolutionChange: (isSelected: boolean) => void;
}

const getUpdatedAssignments = (
  existingAssignments: ITaskAssignment[],
  newAssignmentUsers: IUser[],
  shouldSendInvitationEmail: boolean
) => {
  return newAssignmentUsers.map((assignmentUser) => {
    const existingAssignment = existingAssignments.find(
      ({ assignee_id }) => assignee_id === assignmentUser.id
    );

    return {
      assignee_id: assignmentUser.id,
      access_type: 'user',
      send_invite_email: existingAssignment ? false : shouldSendInvitationEmail,
    };
  });
};

export const EditPageAssigneesField: FC<AssigneesFieldProps> = ({
  taskId,
  canEdit,
  assignments,
  requireResolution,
  onRequireResolutionChange,
}) => {
  const { isPhone } = useAppMediaQuery();
  const { addToast } = useToast();
  const assignStaffModal = useModal();
  const taskResolutionModalState = useModal();
  const callbackSequences = useTasksPagesErrorToastsAndRedirect();

  // * Prefetch modal data
  useSearchUsers({ variables: { page: 1 } });

  const validAssigments = useMemo(
    () => getValidAssigments(assignments),
    [assignments]
  );

  const [currentAssignees, setCurrentAssignees] = useState(
    getCurrentAssignees(validAssigments)
  );
  const [updateTaskAssignments] = useUpdateTaskAssignments({
    refetchQueries: [{ query: GetTaskDocument, variables: { taskId } }],
    onCompleted: callbackSequences.onUpdateTaskSuccess({}),
    onError: () =>
      addToast({ type: 'error', content: 'Could not update your assignees' }),
  });

  const [updateTaskStatus] = useUpdateTaskStatus({
    refetchQueries: [{ query: GetTaskDocument, variables: { taskId } }],
    onError: callbackSequences.onUpdateTaskStatusError,
  });

  const [updateTaskAssigneeStatus] = useUpdateTaskAssigneeStatus({
    refetchQueries: [{ query: GetTaskDocument, variables: { taskId } }],
    onError: callbackSequences.onUpdateTaskStatusError,
  });

  const [sendReminder] = useSendTaskAssignmentReminderEmail({
    onCompleted: () =>
      addToast({
        type: 'success',
        content: 'Reminder has been sent',
      }),
    onError: () =>
      addToast({
        type: 'error',
        content: 'Could not send an assignee task reminder',
      }),
  });

  useEffect(
    function syncLatestAssignments() {
      // This have to be done to get the latest assigment status
      // once it has been updated in the edit screen
      setCurrentAssignees(getCurrentAssignees(validAssigments));
    },
    [validAssigments]
  );

  const handleOnModalDone = (shouldSendInvitationEmail: boolean) => {
    updateTaskAssignments({
      variables: {
        taskId,
        assignments: getUpdatedAssignments(
          validAssigments,
          currentAssignees,
          shouldSendInvitationEmail
        ),
      },
    });
  };

  const handleToggleStatus =
    (assigneeStatus: ITaskStatusEnum) =>
    (assignee: IUser, message?: string | null) => {
      if (
        (!canEdit || (canEdit && assignee.isMe)) &&
        requireResolution &&
        assigneeStatus !== ITaskStatusEnum.Completed
      ) {
        return taskResolutionModalState.open();
      }

      const ownerComment = message ? { ownerComment: { message } } : undefined;

      return updateTaskAssigneeStatus({
        variables: {
          taskId,
          assigneeId: assignee.id,
          status:
            assigneeStatus === ITaskStatusEnum.Completed
              ? ITaskStatusEnum.Pending
              : ITaskStatusEnum.Completed,
          resolution: null,
          ...ownerComment,
        },
      });
    };

  const handleTaskResolutionConfirm = (resolution: TaskResolutionFormData) => {
    updateTaskAssigneeStatus({
      variables: {
        taskId,
        status: ITaskStatusEnum.Completed,
        resolution: {
          summary: resolution?.summary,
          files: transformAttachmentListItemsToTaskAttachments(
            resolution?.files ?? []
          ),
        },
      },
    });

    if (canEdit && resolution?.shouldUpdateMainStatus) {
      updateTaskStatus({
        variables: {
          taskId,
          status: ITaskStatusEnum.Completed,
        },
      });
    }
  };

  const handleReminderMessageSend = canEdit
    ? (assigneeId: string, message: string | null) => {
        sendReminder({
          variables: {
            taskId,
            assigneeId,
            message,
          },
        });
      }
    : undefined;

  return (
    <>
      <FormInputBase
        label="Assignees"
        name="assignments"
        icon={renderTaskFormFieldIcon(<StaffIcon fillColor="#fff" />, isPhone)}
      >
        <ul className="space-y-2">
          {alphabeticallySort(validAssigments)?.map((assignment) => (
            <AssigneeListItem
              key={assignment.assignee_id}
              canEdit={canEdit}
              user={assignment.assignee as RenderableUser}
              resolution={assignment.resolution}
              isCompleted={assignment.status === ITaskStatusEnum.Completed}
              onTaskStatusToggle={handleToggleStatus(assignment.status)}
              onResolutionUpdate={handleTaskResolutionConfirm}
              onReminderMessageSend={handleReminderMessageSend}
            />
          ))}
        </ul>

        {canEdit ? (
          <div className="mt-4 flex flex-col-reverse items-start justify-between gap-4 sm:flex-row sm:items-center">
            <Button
              status="basic"
              spacing="small"
              onClick={assignStaffModal.open}
              rounded="rounded-full"
            >
              Manage Assignees
            </Button>

            {!isEmpty(assignments) ? (
              <Checkbox
                isSelected={requireResolution ?? false}
                onChange={onRequireResolutionChange}
              >
                <span className="font-semibold">Attachment required </span>
              </Checkbox>
            ) : null}
          </div>
        ) : null}
      </FormInputBase>

      {assignStaffModal.isOpen && (
        <AssignStaffModal
          modalState={assignStaffModal}
          assignedStaff={currentAssignees}
          onChange={setCurrentAssignees}
          onDone={handleOnModalDone}
        />
      )}

      {taskResolutionModalState.isOpen ? (
        <TaskResolutionModal
          isOwner={canEdit}
          onConfirm={handleTaskResolutionConfirm}
          modalState={taskResolutionModalState}
        />
      ) : null}
    </>
  );
};

export const EditPageAssigneesFieldLoadingState: FC = () => {
  return (
    <FormInputBase label="Assignees" name="assignments">
      <Skeleton count={2} />
    </FormInputBase>
  );
};
