import { ITaskAssignment, IUser } from '@tapestry/shared/graphql';
import {
  PaperClipIcon,
  PaperPlaneIcon,
  TickIcon,
} from '@tapestry/shared/icons';
import { ListItem } from '@tapestry/weave';
import { FC } from 'react';
import { twMerge } from 'tailwind-merge';
import { RenderableUser } from './assignee-field-utils';
import { useModal } from '@tapestry/shared/hooks';
import {
  TaskResolutionFormData,
  TaskResolutionModal,
  ViewTaskResolutionModal,
} from '../TaskResolutionModal';
import { GenericEmailMessageModal } from '../GenericEmailMessageModal';
import { UserListItemAvatar } from '../AssignToModal/UserListItemAvatar';
import { getInitials } from '../../utils/user';

interface AssigneeListItemProps {
  isCompleted: boolean;
  canEdit?: boolean;
  resolution: ITaskAssignment['resolution'];
  user: RenderableUser;
  onTaskStatusToggle: (user: IUser, message?: string | null) => void;
  onResolutionUpdate: (formData: TaskResolutionFormData) => void;
  onReminderMessageSend?: (assigneeId: string, message: string | null) => void;
}

export const AssigneeListItem: FC<AssigneeListItemProps> = ({
  user,
  isCompleted,
  onTaskStatusToggle,
  canEdit = false,
  resolution,
  onResolutionUpdate,
  onReminderMessageSend,
}) => {
  const taskResolutionModalState = useModal();
  const reminderMessageModalState = useModal();
  const taskFeedbackModalState = useModal();

  const isMe = user.isMe;
  const editable = canEdit || isMe;
  const assigneeFullName = `${user.first_name} ${user.last_name}`;

  const handleReminderMessageSend = (message: string | null) => {
    if (onReminderMessageSend) {
      onReminderMessageSend(user.id, message);
    }
  };

  const handleTaskStatusToggleButtonClick = () => {
    if (canEdit && isCompleted && !isMe) {
      return taskFeedbackModalState.open();
    }

    onTaskStatusToggle(user);
  };

  const handleTaskFeedbackMessageSend = (message: string | null) => {
    onTaskStatusToggle(user, message);
  };

  return (
    <>
      <ListItem
        justify="between"
        className={twMerge(
          'w-full text-left',
          editable ? 'cursor-pointer' : 'cursor-auto'
        )}
        borderColor={editable ? undefined : 'border-gray-200'}
      >
        <div className="flex items-center overflow-x-hidden">
          <UserListItemAvatar
            label={getInitials(user?.first_name, user?.last_name)}
            imageUrl={user?.photo_url ?? undefined}
          />
          <div className="xs:ml-4 ml-2 truncate text-left">
            <p className="truncate font-semibold text-black">
              {user?.first_name} {user?.last_name}
              {isMe ? (
                <span className="ml-2 font-medium tracking-wide text-gray-300">
                  &#45; me
                </span>
              ) : null}
            </p>
          </div>
        </div>
        <div className="ml-2 flex flex-shrink-0 items-center gap-2 sm:ml-4">
          {/* Send Reminder button */}
          {onReminderMessageSend && !isCompleted && !isMe ? (
            <button
              type="button"
              title={`Send reminder to ${assigneeFullName}`}
              className="hover:bg-gray-hover ring-gray-active text-gray-text inline-flex size-7 items-center justify-center overflow-hidden rounded-full p-1.5 hover:text-black focus:ring-2 sm:size-8 sm:p-2"
              onClick={reminderMessageModalState.open}
              onKeyUp={({ key }) => {
                if (key === 'Enter') {
                  reminderMessageModalState.open();
                }
              }}
            >
              <span className="sr-only">
                Send reminder to {assigneeFullName}
              </span>
              <PaperPlaneIcon />
            </button>
          ) : null}

          {/* View/Edit task resolution button */}
          {resolution ? (
            <button
              title="Display assignee resolution"
              type="button"
              className="hover:bg-gray-hover text-gray-text ring-gray-active inline-flex size-7 items-center justify-center overflow-hidden rounded-full p-1.5 hover:text-black focus:ring-2 sm:size-8 sm:p-2"
              onClick={taskResolutionModalState.open}
              onKeyUp={({ key }) => {
                if (key === 'Enter') {
                  taskResolutionModalState.open();
                }
              }}
            >
              <span className="sr-only">Display assignee resolution</span>
              <PaperClipIcon variant="vertical" fillColor="currentColor" />
            </button>
          ) : null}

          {/* Task status toggle button */}
          <button
            type="button"
            disabled={!editable}
            title={editable ? `Toggle ${assigneeFullName} status` : undefined}
            onClick={handleTaskStatusToggleButtonClick}
            onKeyUp={({ key }) => {
              if (key === 'Enter' && editable) {
                handleTaskStatusToggleButtonClick();
              }
            }}
            className={twMerge(
              'h-7 w-7 p-1 sm:h-10 sm:w-10 sm:p-2',
              'inline-flex flex-shrink-0 items-center justify-center',
              'border-gray-border rounded-full border text-white focus:outline-none',
              isCompleted
                ? 'bg-green text-white'
                : 'text-gray-text hover:bg-green-light hover:text-white'
            )}
          >
            <span className="sr-only">
              Toggle {user?.first_name} {user?.last_name} status
            </span>
            <TickIcon light fillColor="currentColor" />
          </button>
        </div>
      </ListItem>

      {resolution && taskResolutionModalState.isOpen && !isMe ? (
        <ViewTaskResolutionModal
          modalState={taskResolutionModalState}
          assigneeFullName={`${user.first_name} ${user.last_name}`}
          resolution={resolution}
        />
      ) : null}

      {resolution && taskResolutionModalState.isOpen && isMe ? (
        <TaskResolutionModal
          modalState={taskResolutionModalState}
          resolution={resolution}
          isOwner={false}
          onConfirm={onResolutionUpdate}
        />
      ) : null}

      {reminderMessageModalState.isOpen ? (
        <GenericEmailMessageModal
          modalState={reminderMessageModalState}
          title={`Send ${assigneeFullName} a reminder`}
          description="Give assignee context to help with completion"
          placeholder="e.g. Just letting you know this task is nearly due"
          onMessageSend={handleReminderMessageSend}
        />
      ) : null}

      {taskFeedbackModalState.isOpen ? (
        <GenericEmailMessageModal
          modalState={taskFeedbackModalState}
          title="Request to Redo the Task"
          description="Include some context to ensure task is redone correctly"
          placeholder="e.g. Please upload a new photo showing it correctly completed"
          onMessageSend={handleTaskFeedbackMessageSend}
        />
      ) : null}
    </>
  );
};
