import { ITaskStatusEnum, IUser } from '@tapestry/shared/graphql';
import { MinusIcon, PlusIcon } from '@tapestry/shared/icons';
import { ListItem } from '@tapestry/weave';
import { Maybe } from 'graphql/jsutils/Maybe';
import { FC } from 'react';
import { twMerge } from 'tailwind-merge';
import { UserListItemAvatar } from './UserListItemAvatar';
import { getInitials } from '../../utils/user';

type WithFirstName = {
  first_name: string;
  last_name?: string;
};

type WithLastName = {
  first_name?: string;
  last_name: string;
};

export type RenderableUser = {
  id: string;
  email?: Maybe<string>;
  isMe?: Maybe<boolean>;
  photo_url?: Maybe<string>;
  assignmentStatus?: ITaskStatusEnum;
} & (WithFirstName | WithLastName);

interface UserListItemProps {
  user: RenderableUser;
  isSelected: boolean;
  handleToggle: (user: IUser) => void;
}

export const UserListItem: FC<UserListItemProps> = ({
  user,
  isSelected,
  handleToggle,
}) => {
  return (
    <ListItem
      justify="between"
      tag="button"
      // @ts-expect-error - the `ListItem` type as not been properly defined to handle events
      type="button"
      onClick={() => {
        handleToggle(user);
      }}
      onKeyUp={({ key }) => {
        if (key === 'Enter') {
          handleToggle(user);
        }
      }}
      className="group w-full text-left"
    >
      <div className="flex items-center">
        <UserListItemAvatar
          label={getInitials(user?.first_name, user?.last_name)}
          imageUrl={user?.photo_url ?? undefined}
          taskStatus={user?.assignmentStatus}
        />

        <div className="xs:ml-4 ml-2 truncate text-left">
          <p className="font-semibold text-black">
            {user?.first_name} {user?.last_name}
            {user.isMe ? (
              <span className="ml-2 font-medium tracking-wide text-gray-300">
                &#45; me
              </span>
            ) : null}
          </p>
        </div>
      </div>

      <div
        className={twMerge(
          'border-gray-border ml-2 inline-flex h-7 w-7 flex-shrink-0 items-center justify-center rounded-full border p-1 text-white transition-colors duration-150 sm:ml-4 sm:h-10 sm:w-10 sm:p-2',
          isSelected
            ? 'bg-primary group-hover:bg-primary-light text-white'
            : 'text-gray-text group-hover:bg-button-basic-hover group-hover:text-white'
        )}
      >
        {isSelected ? (
          <MinusIcon light fillColor="currentColor" />
        ) : (
          <PlusIcon light fillColor="currentColor" />
        )}
      </div>
    </ListItem>
  );
};
