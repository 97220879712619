import { Card } from '@tapestry/weave';
import { FC } from 'react';
import Image from 'next/image';
import { CloseIcon, FileIcon } from '@tapestry/shared/icons';
import Skeleton from 'react-loading-skeleton';
import { loadable } from '@tapestry/shared/lazy-load';
import { useModal } from '@tapestry/shared/hooks';
import type { AttachmentListItem } from './AttachmentsList.type';

type AttachmentsListItemProps =
  | {
      isLoading: true;
      title?: never;
      url?: never;
      mimeType?: never;
      onRemove?: never;
      handleClickAttachment?: never;
    }
  | (Omit<AttachmentListItem, 'id'> & {
      isLoading?: never;
      onRemove?: () => void;
      handleClickAttachment?: () => void;
    });

const ConfirmRemoveModal = loadable(() => import('./ConfirmRemoveModal'), {
  chunkName: 'remove-attachment-modal',
  ssr: false,
});

const AttachmentsListItem: FC<AttachmentsListItemProps> = ({
  isLoading,
  title,
  url,
  mimeType,
  onRemove,
  handleClickAttachment,
}) => {
  const removeAttachmentModalState = useModal();

  const isImage = mimeType?.includes('image');

  return (
    <>
      <Card
        align="space-between"
        className="relative overflow-hidden h-48 w-48 lg:h-56 lg:w-56 border-gray-border border group has-[:focus]:border-primary"
        spacing="none"
        as="figure"
      >
        <header className="flex justify-between p-2 z-10 w-full">
          <div>{/* TODO[hihg]: implement file owner avatar */}</div>

          {onRemove ? (
            <button
              type="button"
              title="Remove attachment"
              className="bg-gray-hover hover:bg-gray-light focus:bg-gray-hover inline-flex h-6 w-6 items-center justify-center rounded-full p-1 disabled:cursor-wait focus:outline-none focus:ring-2 focus:ring-primary focus:ring-opacity-50"
              onClick={removeAttachmentModalState.open}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  removeAttachmentModalState.open();
                }
              }}
            >
              <span className="sr-only">Remove attachment</span>
              <CloseIcon light fillColor="currentColor" />
            </button>
          ) : null}
        </header>

        {!isLoading && isImage && url ? (
          <Image
            src={url}
            fill
            alt={title ?? 'Loading Image'}
            className="object-cover"
          />
        ) : null}

        <button
          type="button"
          className="z-10 flex-grow w-full flex flex-col justify-end focus:outline-none"
          onClick={handleClickAttachment}
          onKeyUp={({ key }) => {
            if (key === 'Enter') {
              handleClickAttachment?.();
            }
          }}
        >
          <span className="sr-only">open</span>

          <figcaption className="truncate block mt-auto text-left text-white text-xs p-2 bg-black bg-opacity-50 w-full">
            {isLoading ? <Skeleton width={120} /> : title}
          </figcaption>
        </button>

        {isLoading ? (
          <Skeleton
            containerClassName="absolute inset-0 block"
            style={{ height: '100%', lineHeight: 'unset' }}
          />
        ) : null}

        {!isLoading && !isImage ? (
          <div className="size-8 text-gray-light absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2">
            <FileIcon fillColor="currentColor" />
          </div>
        ) : null}
      </Card>

      {onRemove && removeAttachmentModalState.isOpen ? (
        <ConfirmRemoveModal
          modalstate={removeAttachmentModalState}
          attachmentTitle={title}
          onConfirm={onRemove}
        />
      ) : null}
    </>
  );
};

export { AttachmentsListItem };
