import {
  IGetTask,
  IPriorityLevelEnum,
  ITaskStatusEnum,
} from '@tapestry/shared/graphql';
import { UpdateTaskForm } from '../types';
import omit from 'lodash/omit';

type ObjectWithTypename = object & { __typename?: string };

const removeTypenames = (object: ObjectWithTypename) => {
  if (!object.__typename) return object;

  return Object.entries(object).reduce((acc, [key, value]) => {
    if (key !== '__typename') {
      acc[key] = value;
    }

    return acc;
  }, {});
};

const removeTypenamesRecursively = <T extends object>(
  object: T
): Omit<T, '__typename'> => {
  return Object.entries(object).reduce((acc, [key, value]) => {
    const valueIsAnObject =
      typeof value === 'object' && value !== null && !Array.isArray(value);
    const valueHasTypenamePresent = value?.__typename;

    if (valueIsAnObject && valueHasTypenamePresent) {
      const cleaned = removeTypenames(value);
      acc[key] = cleaned;
    } else if (key !== '__typename') {
      acc[key] = value;
    }

    return acc;
  }, {} as Omit<T, '__typename'>);
};

/**
 * Map a full task to the update/edit form default values
 */
export const getDefaultValuesFromTaskData = (
  task: IGetTask['getTask']
): UpdateTaskForm => {
  if (!task) {
    return {
      task_type: 'retail_insight',
      title: '',
      description: null,
      status: ITaskStatusEnum.Pending,
      priority: IPriorityLevelEnum.Medium,
      due_date: null,
      occurrence_manager: null,
      tags: [],
      require_resolution: false,
    };
  }

  const {
    task_type,
    title,
    description,
    status,
    priority,
    due_date,
    occurrence_manager,
    tags,
    require_resolution,
  } = removeTypenamesRecursively(task);

  return {
    task_type: task_type || 'retail_insight',
    title: title || '',
    description: description || null,
    status: status || ITaskStatusEnum.Pending,
    priority: priority || IPriorityLevelEnum.Medium,
    due_date: due_date || null,
    occurrence_manager: occurrence_manager
      ? removeTypenamesRecursively(omit(occurrence_manager, 'id'))
      : null,
    tags: tags || [],
    require_resolution: require_resolution || false,
  };
};
