import { RecurrencyRules, MonthlyRepeatOption } from './types';
import { dateTime } from '@tapestry/shared/utils';
import { ITaskOccurrenceFrequencyEnum } from '@tapestry/shared/graphql';
import omit from 'lodash/omit';

export type DueDateRepeatState = {
  selectedMonthlyRepeatOptionKey: MonthlyRepeatOption['key'];
} & RecurrencyRules;

export type RepeatingRulesAction =
  | {
      type: 'ACTIVE_CHANGE';
      payload: boolean;
    }
  | {
      type: 'FREQUENCY_CHANGE';
      payload: ITaskOccurrenceFrequencyEnum;
    }
  | {
      type: 'DATE_CHANGE';
      payload: string | null;
    }
  | {
      type: 'INTERVAL_CHANGE';
      payload: number;
    }
  | {
      type: 'MONTH_RULE_CHANGE';
      payload: MonthlyRepeatOption;
    }
  | {
      type: 'WEEKDAY_CHANGE';
      payload: { weekday: number[] };
    };

export const repeatingRulesDefaultState: DueDateRepeatState = {
  active: false,
  frequency: ITaskOccurrenceFrequencyEnum.Daily,
  interval: 1,
  end_date: null,
  selectedMonthlyRepeatOptionKey: 'dayOfMonth',
};

/**
 * resets some keys to default
 */
const handleFrequencyChange = (
  state: DueDateRepeatState,
  frequency: ITaskOccurrenceFrequencyEnum
) => {
  const { selectedMonthlyRepeatOptionKey } = repeatingRulesDefaultState;

  let newState = {
    ...state,
    selectedMonthlyRepeatOptionKey,
    frequency,
  };

  if (frequency === ITaskOccurrenceFrequencyEnum.Weekly) {
    newState.rules = {
      weekday: [dateTime.now().day()],
    };
  }

  return newState;
};

export const rulesReducer = (
  state: DueDateRepeatState,
  { type, payload }: RepeatingRulesAction
) => {
  switch (type) {
    case 'ACTIVE_CHANGE':
      return { ...state, active: payload };

    case 'FREQUENCY_CHANGE':
      return handleFrequencyChange(state, payload);

    case 'DATE_CHANGE':
      return { ...state, end_date: payload };

    case 'INTERVAL_CHANGE':
      return { ...state, interval: payload };

    case 'MONTH_RULE_CHANGE':
      if (payload.value) {
        return {
          ...state,
          rules: payload.value,
          selectedMonthlyRepeatOptionKey: payload.key,
        };
      }

      return {
        ...omit(state, ['rules']),
        selectedMonthlyRepeatOptionKey: payload.key,
      };

    case 'WEEKDAY_CHANGE':
      return {
        ...state,
        rules: payload,
      };

    default:
      return state;
  }
};
