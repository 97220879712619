import { MoreButtonEllipsis } from '@tapestry/shared/components';
import { IDropDownActionItem } from '@tapestry/types';
import { Link, ListItem } from '@tapestry/weave';
import { FC } from 'react';
import Skeleton from 'react-loading-skeleton';
import { AvatarGroup } from '../AvatarGroup';
import { AvatarGroupAvatar } from '../AvatarGroup/AvatarGroupAvatar';
import {
  IPriorityLevelEnum,
  ITaskAssignment,
  ITaskStatusEnum,
} from '@tapestry/shared/graphql';
import { twMerge } from 'tailwind-merge';
import { useModal } from '@tapestry/shared/hooks';
import { loadable } from '@tapestry/shared/lazy-load';
import { ROUTE_PATHS } from '@tapestry/shared/constants';
import { getRelativeDisplayDate } from './utils';
import { dateTime } from '@tapestry/shared/utils';
import { TaskStatusToggleButton } from '../TaskStatusToggleButton';
import { getInitials } from '../../utils/user';
import { ExclamationIcon, RepeatIcon } from '@tapestry/shared/icons';

const DeleteTaskModal = loadable(() => import('./DeleteTaskModal'), {
  chunkName: 'archive-task-modal',
  ssr: false,
});

type TaskListItemProps =
  | {
      isLoading: true;
      title?: never;
      assignments?: never;
      status?: never;
      id?: never;
      isMyTask?: never;
      requireResolution?: never;
      priority?: never;
      dueDate?: never;
      isRepeatedTask?: never;
    }
  | {
      isLoading?: false;
      id: string;
      title: string;
      status: ITaskStatusEnum;
      isMyTask: boolean;
      requireResolution: boolean;
      priority?: IPriorityLevelEnum;
      dueDate: null | `${number}-${number}-${number}T00:00:00.0000Z`;
      assignments: ITaskAssignment[] | undefined;
      isRepeatedTask: boolean;
    };

const TaskListItem: FC<TaskListItemProps> = ({
  isLoading,
  title,
  assignments = [],
  status,
  id,
  isMyTask,
  requireResolution,
  priority,
  dueDate,
  isRepeatedTask,
}) => {
  const deleteTaskModal = useModal();
  const currentUserAssignment = assignments?.find(
    (assignment) => assignment.assignee.isMe
  );
  const hasAssignments = assignments.length > 0;

  const isImportant = priority === IPriorityLevelEnum.Highest;
  const isCompleted = status === ITaskStatusEnum.Completed;
  const displayDate = getRelativeDisplayDate(dueDate);
  const isPastDueDate = dateTime.isBeforeNow(dueDate, 'hour');

  const ELLIPSIS_ITEMS: IDropDownActionItem[] = [
    {
      label: 'Delete',
      action() {
        deleteTaskModal.open();
      },
      hide: !isMyTask,
    },
  ];

  const handleNavigateToEdit = () => {
    if (isLoading) return '#';

    return ROUTE_PATHS.build.task.edit(id);
  };

  return (
    <>
      <ListItem
        className={twMerge(
          'block sm:flex sm:flex-row sm:items-center sm:justify-between',
          'focus-within:bg-gray-hover hover:bg-gray-hover transition-colors duration-150',
          isLoading && 'pointer-events-none'
        )}
        aria-busy={isLoading}
      >
        <div className="flex flex-1 items-start overflow-x-auto focus:outline-none sm:items-center">
          {isLoading ? (
            <Skeleton
              circle
              containerClassName="hidden sm:block leading-none"
              className="size-10 border sm:size-12"
            />
          ) : (
            <TaskStatusToggleButton
              taskId={id}
              isOwner={isMyTask}
              taskStatus={status}
              requireResolution={requireResolution}
              currentUserAssignment={currentUserAssignment}
              className="hidden sm:block"
            />
          )}

          <Link
            href={handleNavigateToEdit()}
            className="mt-0.5 flex w-full items-start justify-between overflow-x-auto text-left sm:mt-0 sm:flex-row-reverse sm:items-center sm:justify-end sm:gap-2 sm:pl-4"
          >
            <div className="w-full">
              <div className="flex flex-row items-start justify-between gap-2">
                <h2 className="flex-1 overflow-hidden truncate text-lg font-bold leading-6">
                  {isLoading ? <Skeleton width={150} /> : title}
                </h2>

                {isImportant && !isLoading ? (
                  <span className="bg-red-light text-red flex size-6 place-content-center rounded-full p-1.5 sm:hidden">
                    <ExclamationIcon fillColor="currentColor" />
                  </span>
                ) : null}
              </div>

              <p className="@2xl:hidden">
                {isLoading ? <Skeleton width={100} /> : null}
                {!isLoading && dueDate && !isCompleted ? (
                  <span
                    className={twMerge(
                      isPastDueDate ? 'text-red' : 'text-gray-text'
                    )}
                  >
                    {isPastDueDate ? 'Overdue Due' : 'Due'}{' '}
                    <time dateTime={dueDate}>{displayDate}</time>
                  </span>
                ) : null}
              </p>
            </div>
          </Link>

          <span className="block sm:hidden">
            {isLoading ? (
              <span className="inline-flex size-7 items-center justify-center">
                <Skeleton width={10} />
              </span>
            ) : (
              <MoreButtonEllipsis items={ELLIPSIS_ITEMS} ellipsisSize="small" />
            )}
          </span>
        </div>

        <div
          className={
            'mt-2 flex items-center justify-between gap-x-4 sm:mt-0 sm:justify-end sm:pl-4'
          }
        >
          {isImportant && !isLoading ? (
            <span className="bg-red-light text-red hidden size-6 place-content-center rounded-full p-1.5 sm:inline-flex">
              <ExclamationIcon fillColor="currentColor" />
            </span>
          ) : null}

          {isRepeatedTask && !isLoading ? (
            <span className="text-primary hidden size-6 place-content-center sm:inline-flex">
              <RepeatIcon fillColor="currentColor" />
            </span>
          ) : null}

          {isLoading ? (
            <Skeleton width={100} />
          ) : (
            <p
              className={twMerge(
                'hidden',
                dueDate && !isCompleted && '@2xl:block',
                isPastDueDate ? 'text-red' : 'text-gray-text'
              )}
            >
              <span
                className={twMerge(
                  'block',
                  isPastDueDate ? 'text-red' : 'text-gray-text'
                )}
              >
                {isPastDueDate ? 'Overdue' : 'Due'}{' '}
                <time dateTime={dueDate || ''}>{displayDate}</time>
              </span>
            </p>
          )}

          {isLoading ? <Skeleton circle className="size-7 p-1" /> : null}

          {!isLoading && hasAssignments ? (
            <div className="group-[.tasks-list-visualisation]:hidden">
              <AvatarGroup>
                {assignments.map(
                  ({
                    assignee_id,
                    assignee: { first_name, last_name, photo_url },
                    status,
                  }) => (
                    <AvatarGroupAvatar
                      key={assignee_id}
                      taskStatus={status}
                      label={getInitials(first_name, last_name)}
                      title={`${first_name} ${last_name}`}
                      imageUrl={photo_url ?? ''}
                    />
                  )
                )}
              </AvatarGroup>
            </div>
          ) : null}

          {isRepeatedTask && !isLoading ? (
            <div className="text-primary size-6 place-content-center sm:hidden">
              <RepeatIcon fillColor="currentColor" />
            </div>
          ) : null}

          <span className="hidden sm:block">
            <MoreButtonEllipsis
              items={ELLIPSIS_ITEMS}
              ellipsisSize="small"
              isLoading={isLoading}
            />
          </span>

          {isLoading ? (
            <Skeleton
              circle
              containerClassName="sm:hidden leading-none"
              className="size-10 border sm:size-12"
            />
          ) : (
            <TaskStatusToggleButton
              taskId={id}
              isOwner={isMyTask}
              taskStatus={status}
              requireResolution={requireResolution}
              currentUserAssignment={currentUserAssignment}
              className="ml-auto sm:hidden"
            />
          )}
        </div>
      </ListItem>

      {deleteTaskModal.isOpen && id && (
        <DeleteTaskModal
          taskId={id}
          taskTitle={title}
          modalstate={deleteTaskModal}
        />
      )}
    </>
  );
};

export { TaskListItem };
