import { AppletFilterButton } from '@tapestry/shared/components';
import { HeaderSortFilter } from '@tapestry/shared/components';
import { IGetTaskSortEnum } from '@tapestry/shared/graphql';
import { useModal, useUpdateUserSettings } from '@tapestry/shared/hooks';
import { FC } from 'react';
import TasksAppletFilterModal from './TasksAppletFiltersModal';
import { useQueryParam } from '@tapestry/shared/utils';
import { UserSetting } from '@tapestry/types';
import { TasksAppletFilters } from '../../types';

interface TasksListPageHeader {
  handleSetSort: (sort: IGetTaskSortEnum) => void;
  activeFilters: TasksAppletFilters | undefined;
  handleSetActiveFilters: ReturnType<
    typeof useQueryParam<TasksAppletFilters | undefined>
  >[1];
}

const SORT_OPTIONS = [
  {
    label: 'Newest First',
    shorthand: 'Newest First',
    id: IGetTaskSortEnum.CreatedAtDesc,
  },
  {
    label: 'Oldest First',
    shorthand: 'Oldest First',
    id: IGetTaskSortEnum.CreatedAtAsc,
  },
];

/**
 * Main component
 */
export const TasksListPageHeader: FC<TasksListPageHeader> = ({
  handleSetSort,
  activeFilters,
  handleSetActiveFilters,
}) => {
  const filtersModalState = useModal();
  const updateUserSettings = useUpdateUserSettings();

  const activeFiltersCount = activeFilters
    ? Object.keys(activeFilters).length
    : 0;

  const setActiveFiltersAsUserDefaults = (
    filters: TasksAppletFilters | null
  ) => {
    updateUserSettings(UserSetting.applet_tasks_filters, filters);
  };

  const handleFiltersReset = () => {
    setActiveFiltersAsUserDefaults(null);
    handleSetActiveFilters(undefined);
  };

  const handleOnChange = (sort: string) => {
    handleSetSort(sort as IGetTaskSortEnum);
  };

  return (
    <div className="flex items-center justify-end space-x-4">
      <AppletFilterButton
        activeFiltersCount={activeFiltersCount}
        handleOpenModal={filtersModalState.open}
        handleFiltersReset={handleFiltersReset}
      />

      <HeaderSortFilter sortOptions={SORT_OPTIONS} onChange={handleOnChange} />

      {filtersModalState.isOpen && (
        <TasksAppletFilterModal
          modalState={filtersModalState}
          filters={activeFilters}
          onFilterSubmit={handleSetActiveFilters}
          handleSetAsDefaultFilters={setActiveFiltersAsUserDefaults}
          handleFiltersReset={handleFiltersReset}
        />
      )}
    </div>
  );
};
