import { Button, FormInputBase } from '@tapestry/weave';
import { FC, useMemo } from 'react';
import { useModal } from '@tapestry/shared/hooks';
import TagModal from '../TagsModal/TagModal';
import { IOrganisationTag } from '@tapestry/shared/graphql';
import type { Tag as TagType } from '../../types';
import { Tag } from './Tag';

type TagsProps = {
  tags: TagType[];
  editable: boolean;
  onChange: (tags: IOrganisationTag[]) => void;
};

const Tags: FC<TagsProps> = ({ tags, editable, onChange }) => {
  const tagModal = useModal();

  const sortedTag = useMemo(() => {
    return [...tags].sort((a, b) => a.name.length - b.name.length);
  }, [tags]);

  const handleRemoveTag = useMemo(() => {
    if (!editable) return undefined;

    return (tagId: string) => {
      const newListOfTags = sortedTag.filter((_tag) => _tag.id !== tagId);

      onChange(newListOfTags);
    };
  }, [editable, sortedTag, onChange]);

  return (
    <>
      <FormInputBase
        label="Tags"
        name="Tags"
        sublabel="Add tags to this task for easy filtering"
      >
        <div className="mb-4 flex flex-wrap gap-2">
          {sortedTag.map(({ id, name }) => (
            <Tag id={id} key={id} name={name} onClear={handleRemoveTag} />
          ))}
        </div>
        {editable ? (
          <Button
            status="basic"
            spacing="small"
            rounded="rounded-full"
            onClick={tagModal.open}
          >
            Add Tags
          </Button>
        ) : null}
      </FormInputBase>

      {tagModal.isOpen ? (
        <TagModal
          modalState={tagModal}
          assignedTags={sortedTag}
          onDone={onChange}
        />
      ) : null}
    </>
  );
};

export { Tags };
