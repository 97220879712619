import { ITaskStatusEnum } from '@tapestry/shared/graphql';
import { TickIcon } from '@tapestry/shared/icons';
import { FC } from 'react';
import { twMerge } from 'tailwind-merge';
import Image from 'next/image';

export type UserListItemAvatarProps = {
  label: string;
  title?: string;
  imageUrl?: string;
  taskStatus?: ITaskStatusEnum;
};

const IMAGE_SIZE = 48;

const UserListItemAvatar: FC<UserListItemAvatarProps> = ({
  taskStatus,
  label,
  imageUrl,
  title,
}) => {
  const caption = title ?? label;
  const isCompletedTask = taskStatus === ITaskStatusEnum.Completed;

  const className = twMerge(
    'relative block size-10 sm:size-12 rounded-full border-2 bg-gray-lightest overflow-hidden',
    isCompletedTask ? 'border-green' : 'border-gray-lightest'
  );

  return (
    <figure className="cursor relative flex-shrink-0" title={caption}>
      {imageUrl ? (
        <Image
          src={imageUrl}
          alt={caption}
          className={className}
          width={IMAGE_SIZE}
          height={IMAGE_SIZE}
        />
      ) : (
        <span
          className={twMerge(
            className,
            'bg-cerulean-light flex items-center justify-center'
          )}
        >
          {label}
        </span>
      )}
      <figcaption className="sr-only">{caption}</figcaption>
      {taskStatus ? (
        <span
          className={twMerge(
            'absolute bottom-0 right-0 z-10 block size-4 transform rounded-full p-1 ',
            isCompletedTask
              ? 'bg-green text-white'
              : 'bg-gray-border text-white'
          )}
        >
          <TickIcon fillColor="currentColor" />
        </span>
      ) : null}
    </figure>
  );
};

export { UserListItemAvatar };
