import {
  Field,
  Label,
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
} from '@headlessui/react';
import { getMonthlyRepeatOptions } from './utils/get-montly-repeat-options';
import { FC, useMemo } from 'react';
import { DueDateRepeatState, RepeatingRulesAction } from '../../reducer';
import { IsoString } from '@tapestry/types';
import { useFlags } from 'launchdarkly-react-client-sdk';

interface MonthlyOptionsProps {
  selectedMonthlyRepeatOptionKey: DueDateRepeatState['selectedMonthlyRepeatOptionKey'];
  dueDate: IsoString | null;
  dispatch: React.Dispatch<RepeatingRulesAction>;
  disabled: boolean;
}

export const MonthlyOptions: FC<MonthlyOptionsProps> = ({
  dispatch,
  selectedMonthlyRepeatOptionKey,
  dueDate,
  disabled,
}) => {
  const { reapeatDueDateMonthlyOnTheWeekdayOptions: hasRepeatOnWeekdayFlag } =
    useFlags();
  const MONTHLY_REPEAT_OPTIONS = useMemo(() => {
    const options = getMonthlyRepeatOptions(dueDate);

    return hasRepeatOnWeekdayFlag
      ? options
      : options.filter((option) => option.value === null);
  }, [dueDate, hasRepeatOnWeekdayFlag]);

  const selectedOption = MONTHLY_REPEAT_OPTIONS.find(
    ({ key }) => key === selectedMonthlyRepeatOptionKey
  );

  return (
    <Field>
      <Label className="sr-only">Select Monthly Repeat Option</Label>
      <Listbox
        value={selectedOption}
        onChange={(payload) => {
          dispatch({
            type: 'MONTH_RULE_CHANGE',
            payload,
          });
        }}
        disabled={disabled}
      >
        <ListboxButton className="border-gray-lightest focus:border-orange-hank focus:ring-orange-hank relative flex w-full cursor-default items-center justify-between truncate rounded-md border bg-white py-3 pl-3 pr-3 text-left text-sm capitalize focus:outline-none focus:ring-1 disabled:cursor-pointer sm:text-xl lg:w-1/2">
          {selectedOption?.label}
        </ListboxButton>
        <ListboxOptions
          anchor="bottom"
          className="z-dropdown xs:w-64 w-[100% - 2rem] space-y-2 rounded-md bg-white p-3 shadow-lg"
        >
          {MONTHLY_REPEAT_OPTIONS.map((option) => (
            <ListboxOption key={option.key} value={option}>
              {option.label}
            </ListboxOption>
          ))}
        </ListboxOptions>
      </Listbox>
    </Field>
  );
};
