import { useFileUpload } from '@tapestry/shared/hooks';
import { LoadingSpinnerIcon, PaperClipIcon } from '@tapestry/shared/icons';
import { IAttachment } from '@tapestry/types';
import { FC, useRef, useState } from 'react';
import { twMerge } from 'tailwind-merge';
import Image from 'next/image';

type UploadCardProps = {
  onUploadComplete: (attachment: IAttachment) => void;
};

const UploadCard: FC<UploadCardProps> = ({ onUploadComplete }) => {
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [previewURL, setPreviewURL] = useState<string | null>(null);

  const { uploadFile, isUploading, error } = useFileUpload({
    onSuccess: (data) => {
      onUploadComplete(data);

      if (previewURL) {
        URL.revokeObjectURL(previewURL);
        setPreviewURL(null);
      }
    },
    onComplete: () => {
      if (fileInputRef.current) {
        fileInputRef.current.value = '';
      }
    },
  });

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.currentTarget?.files?.[0];

    if (!file) {
      return;
    }

    if (file.type.includes('image')) {
      const previewURL = URL.createObjectURL(file);
      setPreviewURL(previewURL);
    }

    uploadFile(file);
  };

  return (
    <label
      htmlFor="attachmentsInput"
      role="button"
      className={twMerge(
        'border-gray-border focus-within:bg-gray-hover relative flex h-48 flex-col items-center justify-center overflow-hidden rounded-lg border p-6 transition-colors duration-150 sm:w-48 lg:h-56 lg:w-56',
        !isUploading && !previewURL && 'w-full',
        isUploading ? 'cursor-wait' : 'cursor-pointer'
      )}
    >
      {previewURL ? (
        <Image src={previewURL} fill alt="Preview" className="object-cover" />
      ) : null}

      {isUploading && !error ? (
        <>
          <div className="absolute inset-0 bg-white opacity-75" />
          <span className="text-pink h-8 w-8">
            <LoadingSpinnerIcon fillColor="currentColor" />
          </span>
          <span
            className="inline-block font-semibold text-black"
            aria-busy="true"
          >
            Uploading...
          </span>
        </>
      ) : null}

      {!previewURL && !isUploading && !error ? (
        <>
          <div className="text-gray-icon mb-2 flex h-8 items-center justify-center">
            <PaperClipIcon fillColor="currentColor" />
          </div>
          <p className="text-center font-semibold text-black">
            Attach file or image
          </p>
        </>
      ) : null}

      {error ? (
        <>
          <div className="absolute inset-0 bg-white opacity-75" />
          <p className="text-red z-10 inline-flex flex-col items-center">
            <span>Upload failed</span>
            <span>Click to try again</span>
          </p>
        </>
      ) : null}

      <input
        id="attachmentsInput"
        type="file"
        disabled={isUploading}
        ref={fileInputRef}
        className="absolute left-0 top-0 h-full w-full cursor-pointer opacity-0"
        accept="capture=camera,.png,.jpg,.jpeg,.gif,.pdf,.doc,.docx,.xls"
        onChange={handleFileChange}
        aria-hidden="true"
      />
    </label>
  );
};

export { UploadCard };
