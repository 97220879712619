import { IUpdateTaskVariables } from '@tapestry/shared/graphql';
import { DeepPartial } from 'react-hook-form';
import { UpdateTaskForm } from '../../../types';

export const restoreDefaultValue = (
  occurrenceManager: NonNullable<UpdateTaskForm['occurrence_manager']>,
  deafaultOccurrenceManager?: UpdateTaskForm['occurrence_manager']
) => {
  if (
    occurrenceManager.active === false &&
    deafaultOccurrenceManager?.active === true &&
    occurrenceManager.end_date === null
  ) {
    return {
      ...deafaultOccurrenceManager,
      active: occurrenceManager.active === false,
    };
  }

  return occurrenceManager;
};

const patchActiveStatus = (
  occurrenceManager: NonNullable<UpdateTaskForm['occurrence_manager']>
) => {
  return { ...occurrenceManager, active: occurrenceManager.active ?? false };
};

export const transformUpdateTaskFormDataToAPIFields = (
  formData: UpdateTaskForm,
  defaultValues?: DeepPartial<UpdateTaskForm>
): IUpdateTaskVariables['fields'] => {
  const { occurrence_manager, tags } = formData;
  const tagsIds = tags.map(({ id }) => id);

  if (!occurrence_manager) {
    return {
      ...formData,
      tags: tagsIds,
      occurrence_manager: null,
    };
  }

  const defaultOccurrenceManager = defaultValues?.occurrence_manager
    ? (defaultValues.occurrence_manager as UpdateTaskForm['occurrence_manager'])
    : null;

  const transformedOccurrenceManager = patchActiveStatus(
    restoreDefaultValue(occurrence_manager, defaultOccurrenceManager)
  );

  return {
    ...formData,
    tags: tagsIds,
    occurrence_manager: transformedOccurrenceManager,
  };
};
