import {
  ITaskAttachment,
  ITaskResolutionFileType,
} from '@tapestry/shared/graphql';
import { AttachmentListItem } from '../components/AttachmentsList';
import { IAttachment } from '@tapestry/types';
import uuid from 'react-uuid';

const transformAttachmentListItemsToTaskAttachments = (
  attachments: AttachmentListItem[]
) =>
  attachments.map((attachment) => ({
    file_name: attachment.title,
    file_path: attachment.url,
    file_type: attachment.mimeType,
  }));

const transformTaskAttachmentsToAttachmentListItems = (
  attachments: ITaskAttachment[]
): AttachmentListItem[] => {
  return attachments.map((attachment) => ({
    id: attachment.id,
    title: attachment.file_name,
    url: attachment.file_path,
    mimeType: attachment.file_type || '',
  }));
};

const transformTaskResolutionAttachmentsToAttachmentListItems = (
  attachments: ITaskResolutionFileType[]
): AttachmentListItem[] => {
  return attachments.map((attachment) => ({
    id: uuid(),
    title: attachment.file_name,
    url: attachment.file_path,
    mimeType: attachment.file_type || '',
  }));
};

const transformUploadResponseToAttachmentListItem = (reponse: IAttachment) => ({
  id: reponse.id,
  title: reponse.title,
  url: reponse.url,
  mimeType: reponse.file_mime || '',
});

export {
  transformTaskAttachmentsToAttachmentListItems,
  transformAttachmentListItemsToTaskAttachments,
  transformUploadResponseToAttachmentListItem,
  transformTaskResolutionAttachmentsToAttachmentListItems,
};
