import { Children, FC, ReactElement } from 'react';
import { AvatarGroupAvatar, AvatarGroupAvatarProps } from './AvatarGroupAvatar';

// TODO[medium]: fix the type to accept only AvatarGroupAvatar
type AvatarGroupProps = {
  children:
    | ReactElement<AvatarGroupAvatarProps>
    | Array<ReactElement<AvatarGroupAvatarProps>>;
};

// TODO[low]: take the count of avatars to show as a prop
const SHOW_AVATAR_COUNT = 3;

const AvatarGroup: FC<AvatarGroupProps> = ({ children }) => {
  const totalAvatarCount = Children.count(children);
  const avatars = Children.toArray(children).slice(0, SHOW_AVATAR_COUNT);
  const remainingAvatarCount = totalAvatarCount - SHOW_AVATAR_COUNT;

  if (totalAvatarCount === 0) {
    return null;
  }

  return (
    <div className="relative isolate flex flex-shrink-0">
      {avatars}
      {remainingAvatarCount > 0 ? (
        <AvatarGroupAvatar
          label={`+${remainingAvatarCount.toString()}`}
          taskStatus="PENDING"
        />
      ) : null}
    </div>
  );
};

export { AvatarGroup };
