import { ErrorBoundary, useToast } from '@tapestry/shared/client';
import { LoadingSpinnerIcon, SearchIcon } from '@tapestry/shared/icons';
import { FormTextInput, Modal, ModalState, Stack } from '@tapestry/weave';
import { FC, useEffect, useState } from 'react';
import {
  IOrganisationTag,
  useAddOrganisationTag,
  useSearchTags,
} from '@tapestry/shared/graphql';
import { useDebounce } from 'use-debounce';
import { Tag } from '../../types';
import { TagList } from './TagList';
import { NewTagListItem } from './NewTagItem';

type TagsModalProps = {
  modalState: ModalState;
  assignedTags: Tag[];
  onChange?: (tags: IOrganisationTag[]) => void;
  onDone: (tags: IOrganisationTag[]) => void;
};

const TagsModal: FC<TagsModalProps> = ({
  assignedTags,
  modalState,
  onChange,
  onDone,
}) => {
  const { addToast } = useToast();
  const [selectedTags, setSelectedTags] = useState<Tag[]>(assignedTags);
  const [query, setQuery] = useState<string>('');
  const [deboucedQuery] = useDebounce(query, 250);

  const {
    data,
    error,
    loading: isLoading,
    refetch: searchTags,
    fetchMore: fetchMoreUsers,
  } = useSearchTags({
    notifyOnNetworkStatusChange: true,
    variables: { page: 1 },
  });
  const tags = data?.infiniteTagSimple?.items || [];

  const [createTag, { loading: isCreatingTag }] = useAddOrganisationTag({
    notifyOnNetworkStatusChange: true,
    onCompleted(data) {
      const newTag = data?.addOrganisationTag;

      if (newTag) {
        handleToggleTag(newTag);

        setQuery('');
      }
    },
    onError: () => {
      addToast({
        type: 'error',
        content: 'An error has occured whilst creating a new tag',
      });
    },
  });

  const handleFetchMore = () => {
    const pagination = data?.infiniteTagSimple?.pagination;

    const shouldFetchNextPage =
      !isLoading &&
      pagination &&
      pagination.currentPage + 1 <= pagination.totalPages;

    if (!shouldFetchNextPage) return;

    fetchMoreUsers({
      variables: {
        page: pagination.currentPage + 1,
      },
    });
  };

  const handleToggleTag = (tag: IOrganisationTag) => {
    const isSelected = selectedTags.some((_tag) => _tag.id === tag.id);

    const newListOfTags = isSelected
      ? selectedTags.filter((_tag) => _tag.id !== tag.id)
      : [...selectedTags, tag];

    setSelectedTags(() => {
      onChange?.(newListOfTags);

      return newListOfTags;
    });
  };

  const handleOnError = () => {
    addToast({
      type: 'error',
      content: 'A error has occured in the assign user modal. Contact support',
    });
    modalState.close();
  };

  const handleDoneButtonClilck = () => {
    modalState.close();
    onDone(selectedTags);
  };

  const handleCreateNewTag = () => {
    createTag({ variables: { name: query } });
  };

  useEffect(
    function searchTagsOnQueryChange() {
      searchTags({
        search: deboucedQuery,
        page: 1,
      });
    },
    [deboucedQuery, searchTags]
  );

  return (
    <ErrorBoundary onError={handleOnError}>
      <Modal state={modalState}>
        <Modal.Dialog>
          <Modal.Content>
            <Modal.Title>Assign tags to this task</Modal.Title>
            <Modal.Description>
              Add, search and remove tags from this task
            </Modal.Description>

            <Stack spacing="xsmall" hasTopMargin>
              <FormTextInput
                label="Search"
                name="search"
                autoFocus
                labelSROnly
                placeholder="Search tags..."
                iconRight={<SearchIcon fillColor="#666" />}
                value={query}
                onChange={(e) => setQuery(e?.currentTarget?.value)}
              />

              <header className="flex items-center pt-2">
                <p className="text-gray-text text-left">
                  Select tags to add to this task
                </p>
                {isLoading && (
                  <LoadingSpinnerIcon
                    fillColor="#0e73bb"
                    className="ml-4 h-4 w-auto"
                  />
                )}
              </header>

              {error && (
                <p role="alert" className="text-danger">
                  An error has occured whislt searching your tags
                </p>
              )}

              {tags.length === 0 && query.length > 0 && (
                <NewTagListItem
                  name={query}
                  isCreating={isCreatingTag}
                  onClick={handleCreateNewTag}
                />
              )}

              <TagList
                tags={tags}
                currentlyAssignedTags={selectedTags}
                fetchMoreThreshold={5}
                onFetchMore={handleFetchMore}
                handleToggleTag={handleToggleTag}
              />
            </Stack>
          </Modal.Content>

          <Modal.Footer>
            <Modal.Button status="primary" onClick={handleDoneButtonClilck}>
              Done
            </Modal.Button>
          </Modal.Footer>
        </Modal.Dialog>
      </Modal>
    </ErrorBoundary>
  );
};

export default TagsModal;
