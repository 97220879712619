import { MinusIcon, PlusIcon, TagIcon } from '@tapestry/shared/icons';
import { ListItem } from '@tapestry/weave';
import { FC } from 'react';
import { twMerge } from 'tailwind-merge';
import type { Tag } from '../../types';

type TagListItemProps = {
  tag: Tag;
  isSelected: boolean;
  handleToggle: (tag: Tag) => void;
};

const TagListItem: FC<TagListItemProps> = ({
  tag,
  isSelected,
  handleToggle,
}) => {
  return (
    <ListItem
      justify="between"
      tag="button"
      // @ts-expect-error - the `ListItem` type as not been properly defined to handle events
      type="button"
      onClick={() => {
        handleToggle(tag);
      }}
      onKeyUp={({ key }) => {
        if (key === 'Enter') {
          handleToggle(tag);
        }
      }}
      className="group w-full text-left"
    >
      <div className="flex items-center overflow-hidden">
        <figure className="cursor relative flex-shrink-0" title={tag.name}>
          <span className="bg-pink flex size-10 rounded-full p-2.5 text-white sm:size-12  sm:p-3">
            <TagIcon fillColor="currentColor" />
          </span>
        </figure>

        <p className="xs:ml-4 ml-2 truncate text-left font-semibold text-black">
          {tag.name}
        </p>
      </div>

      <div
        className={twMerge(
          'border-gray-border ml-2 inline-flex h-7 w-7 flex-shrink-0 items-center justify-center rounded-full border p-1 text-white transition-colors duration-150 sm:ml-4 sm:h-10 sm:w-10 sm:p-2',
          isSelected
            ? 'bg-primary group-hover:bg-primary-light text-white'
            : 'text-gray-text group-hover:bg-button-basic-hover group-hover:text-white'
        )}
      >
        {isSelected ? (
          <MinusIcon light fillColor="currentColor" />
        ) : (
          <PlusIcon light fillColor="currentColor" />
        )}
      </div>
    </ListItem>
  );
};

export { TagListItem };
