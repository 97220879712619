import { FormTextArea, Modal, ModalState } from '@tapestry/weave';
import { FC, useRef } from 'react';

type GenericEmailMessageModalProps = {
  modalState: ModalState;
  title: string;
  description: string;
  placeholder: string;
  onMessageSend: (message: string | null) => void;
};

const GenericEmailMessageModal: FC<GenericEmailMessageModalProps> = ({
  modalState,
  title,
  description,
  placeholder,
  onMessageSend,
}) => {
  const textAreaRef = useRef<HTMLTextAreaElement>(null);

  const handleFormSubmit = (formEvent: React.FormEvent<HTMLFormElement>) => {
    formEvent.preventDefault();

    const formData = new FormData(formEvent.currentTarget);
    const message = formData.get('message') as string;

    onMessageSend(message ?? null);

    modalState.close();
  };

  return (
    <Modal state={modalState}>
      <form onSubmit={handleFormSubmit}>
        <Modal.Dialog>
          <Modal.Content>
            <Modal.Title>{title}</Modal.Title>
            <Modal.Description>{description}</Modal.Description>
            <FormTextArea
              labelSROnly
              autoFocus
              ref={textAreaRef}
              name="message"
              label="Message"
              placeholder={placeholder}
            />
          </Modal.Content>

          <Modal.Footer>
            <Modal.Button status="primary" type="submit">
              Send
            </Modal.Button>
          </Modal.Footer>
        </Modal.Dialog>
      </form>
    </Modal>
  );
};

export { GenericEmailMessageModal };
