import { IAttachment } from '@tapestry/types';
import { FC, useCallback, useMemo, useState } from 'react';
import { UploadCard } from './UploadCard';
import { Card, SliderTray } from '@tapestry/weave';
import { AttachmentsListItem } from './AttachmentsListItem';
import { AttachmentListLoadingState } from './AttachmentsListLoadingState';
import isEmpty from 'lodash/isEmpty';
import { UploadButton } from './UploadButton';
import { twMerge } from 'tailwind-merge';
import type { AttachmentListItem } from './AttachmentsList.type';
import { Lightbox } from './Lightbox';

type AttachmentsListProps = {
  isLoading?: boolean;
  isEditable?: boolean;
  attachments: AttachmentListItem[];
  onUploadComplete: (attachment: IAttachment) => void;
  onRemoveComplete: (attachmentId: string) => void;
};

const makeSlides = (attachments: { url: string; title: string }[]) =>
  attachments.map((attch) => ({ src: attch.url, alt: attch.title }));

const AttachmentsList: FC<AttachmentsListProps> = ({
  isLoading = false,
  isEditable = false,
  attachments = [],
  onUploadComplete,
  onRemoveComplete,
}) => {
  const isListEmpty = isEmpty(attachments);
  const [shouldOpenLightbox, setshouldOpenLightbox] = useState(false);
  const [lightboxIndex, setLightboxIndex] = useState(0);
  const slides = useMemo(() => makeSlides(attachments), [attachments]);

  const handleOpenlightboxAt = (idx: number) => () => {
    setLightboxIndex(idx);
    setshouldOpenLightbox(true);
  };

  const handleUploadComplete = (attachment: IAttachment) => {
    if (onUploadComplete) {
      onUploadComplete(attachment);
    }
  };

  const handleOnRemoveComplete = useCallback(
    (attachmentId: string) => {
      if (!isEditable) {
        return;
      }

      return () => {
        if (onRemoveComplete) {
          onRemoveComplete(attachmentId);
        }
      };
    },
    [isEditable, onRemoveComplete]
  );

  return (
    <>
      <div className="flex flex-col lg:flex-row lg:space-x-3">
        {isLoading ? <AttachmentListLoadingState /> : null}

        {!isLoading ? (
          <>
            {isEditable ? (
              <div
                className={twMerge(
                  'flex-shrink-0',
                  isListEmpty ? 'block' : 'hidden lg:block'
                )}
              >
                <UploadCard onUploadComplete={handleUploadComplete} />
              </div>
            ) : null}

            {!isListEmpty ? (
              <div className="flex-1 overflow-hidden">
                <SliderTray
                  hideArrowsBelowTablet
                  containerClassName="max-w-screen-xl xl:mx-auto"
                  listClassName="p-0"
                  arrowClassName="translate-x-0"
                >
                  {attachments.map(({ id, title, url, mimeType }, idx) => (
                    <AttachmentsListItem
                      key={id}
                      title={title}
                      url={url}
                      mimeType={mimeType}
                      onRemove={handleOnRemoveComplete(id)}
                      handleClickAttachment={handleOpenlightboxAt(idx)}
                    />
                  ))}
                </SliderTray>
              </div>
            ) : null}

            {isListEmpty && !isEditable ? (
              <Card
                bgColor="bg-gray-lightest"
                as="p"
                className={twMerge(
                  'text-gray-text block',
                  isEditable && 'lg:hidden'
                )}
              >
                No attachment added. Click the button below to add
              </Card>
            ) : null}

            {isEditable && !isListEmpty ? (
              <div className="block text-left lg:hidden">
                <UploadButton onUploadComplete={handleUploadComplete} />
              </div>
            ) : null}
          </>
        ) : null}
      </div>

      <Lightbox
        slides={slides}
        open={shouldOpenLightbox}
        handleClose={() => setshouldOpenLightbox(false)}
        index={lightboxIndex}
      />
    </>
  );
};

export { AttachmentsList };
