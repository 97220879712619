import { dateTime } from '@tapestry/shared/utils';
import { IsoString } from '@tapestry/types';
import { getWeekdayIterationWithinMonth } from './get-weekday-iteration-within-month';

/**
 * Parse and Extract some date-time information from the due date
 */
export const getDueDateMeta = (dueDate: IsoString) => {
  const parsedDate = dateTime.parse(dueDate);

  return {
    dueDay: parsedDate.get('date'),
    dueWeekDay: parsedDate.get('day'),
    nthWeekday: getWeekdayIterationWithinMonth(dueDate),
    ordinalNumberDay: parsedDate.format('Do'),
    isLastDayOfMonth: dateTime.isLastDayOfMonth(dueDate),
  };
};
