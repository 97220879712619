import { SliderTray } from '@tapestry/weave';
import { AttachmentsListItem } from './AttachmentsListItem';

const AttachmentListLoadingState = () => {
  return (
    <SliderTray
      noEdgeGradients
      hideArrowsBelowTablet
      containerClassName="max-w-screen-xl xl:mx-auto"
      arrowClassName="translate-x-0"
    >
      {Array(4)
        .fill(null)
        .map((_, index) => (
          <AttachmentsListItem key={index} isLoading />
        ))}
    </SliderTray>
  );
};

export { AttachmentListLoadingState };
