import { ICreateTaskRetailInsightFieldsType } from '@tapestry/shared/graphql';
import { CreateTaskForm } from '../../../types';
import { transformAttachmentListItemsToTaskAttachments } from '../../../utils/attachment';
import { mapUsersToAssignees } from '../../../utils/map-users-to-assignees';

export const transformCreateTaskFormDataToAPIFields = (
  formData: CreateTaskForm
): ICreateTaskRetailInsightFieldsType => {
  const {
    assignments,
    attachments,
    shouldSendInvitationEmail,
    tags,
    ...restFormData
  } = formData;

  return {
    ...restFormData,
    assignments: mapUsersToAssignees(assignments, shouldSendInvitationEmail),
    attachments: transformAttachmentListItemsToTaskAttachments(attachments),
    tags: tags.map((tag) => tag.id),
  };
};
