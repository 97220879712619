import { ISearchTags, ISearchUsers } from '@tapestry/shared/graphql';
import { ComboBoxOptionItem } from '@tapestry/types';

export const transformUsersToOptions = (
  data: ISearchUsers | undefined
): ComboBoxOptionItem[] => {
  const users = data?.infiniteUserSimple?.items || [];

  return users.map((user) => ({
    id: user.id,
    label: `${user.first_name} ${user.last_name}`,
    photoUrl: user.photo_url,
  }));
};

export const transformTagsToOptions = (
  data: ISearchTags | undefined
): ComboBoxOptionItem[] => {
  const tags = data?.infiniteTagSimple?.items || [];

  return tags.map((tag) => ({
    id: tag.id,
    label: tag.name,
  }));
};
