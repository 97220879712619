import { ApolloError } from '@apollo/client';
import { useToast } from '@tapestry/shared/client';
import { IUpdateTask } from '@tapestry/shared/graphql';
import { useRouter } from 'next/router';
import { UseFormReset } from 'react-hook-form';
import { UpdateTaskForm } from '../types';
import { getDefaultValuesFromTaskData } from '../utils/get-default-values-from-task';

export const useTasksPagesErrorToastsAndRedirect = () => {
  const { addToast } = useToast();
  const router = useRouter();

  const onTaskError = () => {
    addToast({
      type: 'error',
      content:
        "An error has occured with your task, you'll now be redirected back to your tasks",
    });

    router.back();
  };

  const onUpdateTaskSuccess =
    (config: { reset?: UseFormReset<UpdateTaskForm>; message?: string }) =>
    ({ updateTask }: IUpdateTask) => {
      addToast({
        type: 'success',
        content: config?.message ?? 'Task update successfully.',
      });
      const updatedFields = getDefaultValuesFromTaskData(updateTask);

      config.reset?.(updatedFields);
    };

  return {
    onTaskError,
    onUpdateTaskSuccess,
    onUpdateTaskError: () => {
      addToast({
        type: 'error',
        content: 'An error has occured whilst updating your task.',
      });
    },
    onUpdateTaskStatusError: (error: ApolloError) => {
      addToast({
        type: 'error',
        content: `Failed to update task status. ${error.message}`,
      });
    },
  };
};
