import { useFileUpload } from '@tapestry/shared/hooks';
import { IAttachment } from '@tapestry/types';
import { FC, useRef } from 'react';
import { Button } from '@tapestry/weave';

type UploadButtonProps = {
  onUploadComplete: (attachment: IAttachment) => void;
};

const UploadButton: FC<UploadButtonProps> = ({ onUploadComplete }) => {
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const { uploadFile, isUploading, error } = useFileUpload({
    onSuccess: (data) => {
      onUploadComplete(data);
    },
    onComplete: () => {
      if (fileInputRef.current) {
        fileInputRef.current.value = '';
      }
    },
  });

  const handleUploadButtonClick = () => {
    fileInputRef.current?.click();
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.currentTarget?.files?.[0];

    if (!file) {
      return;
    }

    uploadFile(file);
  };

  return (
    <>
      <Button
        disabled={isUploading}
        status="basic"
        spacing="small"
        className="mt-4"
        onClick={handleUploadButtonClick}
        rounded="rounded-full"
        loading={isUploading}
        loadingLabel="Uploading..."
      >
        <span className="sr-only">Attach image, file or take photo</span>
        Attach image, file or take photo
      </Button>
      {error ? (
        <p className="pt-2 text-red text-sm">
          There is an error while uploading, please try again
        </p>
      ) : null}
      <input
        id="attachmentsInput"
        type="file"
        disabled={isUploading}
        ref={fileInputRef}
        className="hidden"
        accept="capture=camera,.png,.jpg,.jpeg,.gif,.pdf,.doc,.docx,.xls"
        onChange={handleFileChange}
        aria-hidden="true"
      />
    </>
  );
};

export { UploadButton };
