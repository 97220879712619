import { Pill } from '@tapestry/weave';
import type { Tag as TagType } from '../../types';
import { useMemo } from 'react';

type TagProps = TagType & {
  onClear?: (tagId: string) => void;
};

const Tag: React.FC<TagProps> = ({ id, name, onClear }) => {
  const handleOnClear = useMemo(() => {
    if (!onClear) return undefined;

    return (id: string) => () => {
      onClear(id);
    };
  }, [onClear]);

  return (
    <Pill
      title={name}
      size="big"
      label={name}
      textColor="text-white"
      bgColor="bg-pink"
      className="justify-between px-4 py-2 only:max-w-48 sm:py-3"
      onClear={handleOnClear?.(id)}
      textStyle="normal-case"
    />
  );
};

export { Tag };
