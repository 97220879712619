import { ROUTE_PATHS } from '@tapestry/shared/constants';
import { PlusIcon } from '@tapestry/shared/icons';
import { Button, Link } from '@tapestry/weave';
import { FC } from 'react';

type TasksListEmptyStateProps = {
  searchParam?: string;
};

const TasksListEmptyState: FC<TasksListEmptyStateProps> = ({ searchParam }) => (
  <div className="text-gray-text flex w-full flex-col items-center justify-center">
    <h3 className="mb-8 text-center text-base font-bold tracking-wider sm:text-2xl">
      {searchParam
        ? `No task contains "${searchParam}" in the name`
        : 'No tasks has been created or assigned to you.'}
    </h3>
    <Link href={ROUTE_PATHS.createNewTask}>
      <Button status="primary" spacing="lg" className="text-sm sm:text-base">
        <PlusIcon className="mr-2 h-5" light fillColor="#fff" />
        Create a new Task
      </Button>
    </Link>
  </div>
);

export { TasksListEmptyState };
