import { MonthlyOptions } from './components/MonthlyOptions/MonthlyOptions';
import { IsoString } from '@tapestry/types';
import { DatePicker, FormInputBase, Stack, Toggle } from '@tapestry/weave';
import { Maybe } from 'graphql/jsutils/Maybe';
import { FC, useReducer } from 'react';
import { RecurrencyRules } from './types';
import { repeatingRulesDefaultState, rulesReducer } from './reducer';
import { IntervalSelection } from './components/IntervalSelection/IntervalSelection';
import { WeeklyOptions } from './components/WeeklyOptions/WeeklyOptions';
import { dateTime } from '@tapestry/shared/utils';
import { useEffectAfterMount } from '@tapestry/shared/hooks';
import { parseRRulesToState } from '../../utils/parse-task-occurence-manager-type-to-repeating-rules';
import { ITaskOccurrenceManagerTypeWithoutId } from '../../types';
import { Transition } from '@headlessui/react';

type DueDateRepeatProps = {
  rules?: Maybe<ITaskOccurrenceManagerTypeWithoutId>;
  onChange: (dueDateRepeatState: RecurrencyRules | null) => void;
  dueDate: IsoString | null;
  disabled?: boolean;
  error?: {
    end_date?: string;
  };
};

const DueDateRepeat: FC<DueDateRepeatProps> = ({
  rules,
  onChange,
  dueDate,
  disabled = false,
  error,
}) => {
  const [repeatState, dispatch] = useReducer(rulesReducer, {
    ...repeatingRulesDefaultState,
    ...parseRRulesToState(rules),
  });

  const {
    active: isActive,
    frequency,
    end_date,
    selectedMonthlyRepeatOptionKey,
  } = repeatState;

  useEffectAfterMount(
    function updateParent() {
      const {
        end_date,
        selectedMonthlyRepeatOptionKey: _,
        ...recurrency
      } = repeatState;

      const parsedDate = dateTime.parse(end_date, 'YYYY-MM-DD', true);

      onChange({
        ...recurrency,
        end_date: parsedDate.isValid()
          ? parsedDate.endOf('day').toISOString()
          : end_date,
      });
    },
    [repeatState]
  );

  return (
    <FormInputBase
      label="repeat"
      name="repeat"
      sublabel="Set as a repeating task"
    >
      <Stack spacing="small">
        {!disabled && (
          <Toggle
            isSelected={isActive}
            onChange={() => {
              dispatch({ type: 'ACTIVE_CHANGE', payload: !isActive });
            }}
            label={isActive ? 'ON' : 'Off'}
          />
        )}

        {isActive && (
          <div className="space-y-2 sm:space-y-4">
            <IntervalSelection
              frequency={frequency}
              dispatch={dispatch}
              disabled={disabled}
            />

            {frequency === 'WEEKLY' ? (
              <WeeklyOptions
                dispatch={dispatch}
                weekdays={repeatState?.rules?.weekday || []}
                disabled={disabled}
              />
            ) : null}

            {frequency === 'MONTHLY' ? (
              <MonthlyOptions
                dispatch={dispatch}
                selectedMonthlyRepeatOptionKey={selectedMonthlyRepeatOptionKey}
                dueDate={dueDate}
                disabled={disabled}
              />
            ) : null}

            <div className="text-gray-text sm:grid sm:grid-cols-4 sm:gap-x-4 lg:w-1/2">
              <span className="mb-1 flex items-center whitespace-nowrap sm:col-span-1 sm:mb-0">
                End on: *
              </span>

              <div className="w-full sm:col-span-3">
                <DatePicker
                  value={end_date}
                  onChange={(payload) => {
                    dispatch({ type: 'DATE_CHANGE', payload });
                  }}
                  minDate={new Date()}
                  wrapperClassName="w-full sm:col-span-3"
                  disabled={disabled}
                />
                {/* TODO: create a form error component to reuse it  */}
                {error?.end_date ? (
                  <Transition
                    appear
                    show={!!error?.end_date}
                    enter="transition-opacity duration-500"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="transition-opacity duration-500"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                    className=" text-red mt-2 text-sm"
                    as="p"
                    role="alert"
                  >
                    {error?.end_date}
                  </Transition>
                ) : null}
              </div>
            </div>
          </div>
        )}
      </Stack>
    </FormInputBase>
  );
};

export { DueDateRepeat };
