import { dateTime } from '@tapestry/shared/utils';
import { Maybe } from 'graphql/jsutils/Maybe';

export const getRelativeDisplayDate = (
  dueDate: Maybe<`${number}-${number}-${number}T00:00:00.0000Z`>
) => {
  if (!dueDate) return null;

  const relativeDate = dateTime.fromNow(dueDate);
  const isToday = dateTime.parse(dueDate).isSame(dateTime.now(), 'day');

  if (isToday) {
    return relativeDate;
  }

  const diffDays = dateTime.parse(dueDate).diff(dateTime.now(), 'day');
  const isPastDueDate = dateTime.isBeforeNow(dueDate, 'hour');

  const getMoreThanAWeekFormat = () => {
    if (isPastDueDate) {
      return `[${Math.abs(diffDays)} days ago]`;
    }

    return 'DD/MM/YYYY [at] h:mm A';
  };

  return dateTime.calendarWithFormats(dueDate, {
    sameDay: '[Today at] h:mm A', // Today at 2:30 AM
    nextDay: '[Tomorrow at] h:mm A', // Tomorrow at 2:30 AM
    nextWeek: 'dddd [at] h:mm A', // Sunday at 2:30 AM
    lastDay: '[Yesterday at] h:mm A', // Yesterday at 2:30 AM
    lastWeek: '[Last] dddd', // Last Monday at 2:30 AM
    sameElse: getMoreThanAWeekFormat(), // Everything else
  });
};
