import { ListItem } from '@tapestry/weave';
import { FC } from 'react';
import { twMerge } from 'tailwind-merge';
import { LoadingSpinnerIcon, TagIcon } from '@tapestry/shared/icons';

type NewTagListItemProps = {
  name: string;
  isCreating: boolean;
  onClick: () => void;
};

const NewTagListItem: FC<NewTagListItemProps> = ({
  name,
  isCreating,
  onClick,
}) => {
  return (
    <ListItem
      justify="between"
      tag="button"
      // @ts-expect-error - the `ListItem` type as not been properly defined to handle events
      type="button"
      onClick={onClick}
      onKeyUp={({ key }) => {
        if (key === 'Enter') {
          onClick();
        }
      }}
      className={twMerge(
        'group w-full text-left',
        isCreating && 'pointer-events-none'
      )}
    >
      <span className="flex items-center overflow-hidden">
        <figure className="cursor relative flex-shrink-0" title={name}>
          <span className="bg-pink flex size-10 rounded-full p-2.5 text-white sm:size-12 sm:p-3">
            <TagIcon fillColor="currentColor" />
          </span>
        </figure>

        <p className="xs:ml-4 ml-2 truncate text-left font-semibold text-black">
          {name}
        </p>
      </span>

      <span className="text-blue-normal group-hover:text-blue-dark flex-shrink-0 capitalize">
        {isCreating ? (
          <span className="block size-8">
            <LoadingSpinnerIcon fillColor="currentColor" />
          </span>
        ) : (
          'Create new tag'
        )}
      </span>
    </ListItem>
  );
};

export { NewTagListItem };
