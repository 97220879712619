import { ICreateTaskAssignmentType, IUser } from '@tapestry/shared/graphql';

/**
 * create task form utility to transform data to api data
 */
export const mapUsersToAssignees = (
  users: IUser[],
  shouldSendInvitationEmail: boolean
): ICreateTaskAssignmentType[] => {
  return users.map((user) => ({
    assignee_id: user.id,
    access_type: 'user',
    send_invite_email: shouldSendInvitationEmail,
  }));
};
