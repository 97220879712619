import { FC } from 'react';
import { Checkbox } from '@tapestry/weave';
import { IPriorityLevelEnum } from '@tapestry/shared/graphql';

type TaskPriorityProps = {
  priority: IPriorityLevelEnum;
  onChange: (priority: IPriorityLevelEnum) => void;
  label?: string;
  disabled?: boolean;
};

const TaskPriority: FC<TaskPriorityProps> = ({
  priority,
  onChange,
  disabled,
  label = 'This is an important task',
}) => {
  const isImportant = priority === IPriorityLevelEnum.Highest;

  const handleOnChange = (isSelected: boolean) => {
    const _priority = isSelected
      ? IPriorityLevelEnum.Highest
      : IPriorityLevelEnum.Medium;

    onChange(_priority);
  };

  return (
    <Checkbox
      isSelected={isImportant}
      onChange={handleOnChange}
      isDisabled={disabled}
    >
      <span className="font-semibold">{label}</span>
    </Checkbox>
  );
};

export { TaskPriority };
