import { dateTime } from '@tapestry/shared/utils';
import { IsoString } from '@tapestry/types';

/**
 * returns true if the due date is within the last 7 days of the month
 */
export const isWithinTheLastWeek = (dueDay: IsoString | null) => {
  if (!dueDay) return false;

  const parsedDueDate = dateTime.parse(dueDay);
  const weekAfter = parsedDueDate.add(1, 'week');

  return parsedDueDate.get('month') !== weekAfter.get('month');
};
