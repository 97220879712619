import { AppletFilterModalWrapper } from '@tapestry/shared/components';
import {
  IPriorityLevelEnum,
  useSearchTags,
  useSearchUsers,
} from '@tapestry/shared/graphql';
import { StaffIcon, TagIcon, UserIcon } from '@tapestry/shared/icons';
import {
  Avatar,
  Circle,
  DatePicker,
  DateString,
  FormInputBase,
  ModalState,
  MultiCombobox,
} from '@tapestry/weave';
import { FC, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { TasksAppletFilters } from '../../types';
import {
  transformUsersToOptions,
  transformTagsToOptions,
} from './transformers';
import { TaskPriority } from '../TaskPriority';
import {
  mapDueDateFormToFilter,
  mapDueDateFilterToForm,
} from './map-between-form-and-filter-due-date';
import isEmpty from 'lodash/isEmpty';
import { useFlags } from 'launchdarkly-react-client-sdk';

export interface IFilterModalProps {
  modalState: ModalState;
  filters: TasksAppletFilters | undefined;
  onFilterSubmit: (filters: TasksAppletFilters) => void;
  handleSetAsDefaultFilters: (filters: TasksAppletFilters) => void;
  handleFiltersReset: () => void;
}

export type TasksAppletFiltersForm = Omit<TasksAppletFilters, 'due_date'> & {
  setHasDefaultFilters: boolean;
  due_date?: {
    start?: DateString | null;
    end?: DateString | null;
  };
};

const mapPriorityToFilter = (priority: IPriorityLevelEnum | undefined) => {
  return priority === IPriorityLevelEnum.Highest
    ? { priority: IPriorityLevelEnum.Highest }
    : undefined;
};

// TODO: redo typing for this function
const transformComboBoxOptionToFilter = (
  comboboxOptionFilters: Pick<
    TasksAppletFilters,
    'owner_id' | 'assignee_id' | 'tag_id'
  >
) =>
  Object.entries(comboboxOptionFilters).reduce(
    (transformFilters, [key, value]) => ({
      ...transformFilters,
      [key]: !isEmpty(value) ? value : undefined,
    }),
    {}
  );

export const TasksAppletFilterModal: FC<IFilterModalProps> = ({
  modalState,
  filters,
  onFilterSubmit,
  handleSetAsDefaultFilters,
  handleFiltersReset,
}) => {
  const { taskAppletTagsField: hasTagsFieldFlag } = useFlags();

  const {
    handleSubmit,
    control,
    formState: { errors },
    watch,
  } = useForm<TasksAppletFiltersForm>({
    defaultValues: {
      setHasDefaultFilters: false,
      ...filters,
      ...mapDueDateFilterToForm(filters?.due_date),
    },
  });
  const dueDateRangeStart = watch('due_date.start');

  const {
    data,
    loading: isLoading,
    refetch: searchUsers,
    previousData,
  } = useSearchUsers({
    notifyOnNetworkStatusChange: true,
    variables: { page: 1 },
  });
  const usersOptions = useMemo(
    () => transformUsersToOptions(data || previousData),
    [data, previousData]
  );

  const {
    data: tagsData,
    loading: isLoadingTags,
    refetch: searchTags,
    previousData: previousTagData,
  } = useSearchTags({
    notifyOnNetworkStatusChange: true,
    variables: { page: 1 },
  });

  const tagOptions = useMemo(
    () => transformTagsToOptions(tagsData || previousTagData),
    [previousTagData, tagsData]
  );

  const onSubmit = (data: TasksAppletFiltersForm) => {
    const { setHasDefaultFilters, priority, due_date, ...filters } = data;

    const _filters: TasksAppletFilters = {
      ...transformComboBoxOptionToFilter(filters),
      ...mapPriorityToFilter(priority),
      ...mapDueDateFormToFilter(due_date),
    };

    if (setHasDefaultFilters) {
      handleSetAsDefaultFilters(_filters);
    }

    onFilterSubmit(_filters);
    modalState.close();
  };

  const shouldHandleFilterReset = () => {
    handleFiltersReset();
    modalState.close();
  };

  return (
    <AppletFilterModalWrapper
      onSubmit={handleSubmit(onSubmit)}
      control={control}
      handleResetFilters={shouldHandleFilterReset}
      modalState={modalState}
    >
      {/* Owner */}
      <Controller
        control={control}
        name="owner_id"
        render={({ field: { value, ...fieldValue } }) => (
          <MultiCombobox
            input={{
              iconLeft: (
                <Circle size="h-full w-full p-2" className="bg-pink">
                  <UserIcon fillColor="#fff" />
                </Circle>
              ),
            }}
            label={{
              label: 'Owner',
              sublabel: 'Filter by task owner',
            }}
            options={{
              options: usersOptions,
              itemIcon: (item) => (
                <Avatar src={item.photoUrl} alt="Profile photo" size="full" />
              ),
              isQueryable: {
                query(search: string) {
                  searchUsers({
                    search,
                    page: 1,
                  });
                },
                loading: isLoading,
              },
            }}
            defaultOptions={value}
            {...fieldValue}
          />
        )}
      />

      {/* Assignees */}
      <Controller
        control={control}
        name="assignee_id"
        render={({ field: { value, ...fieldValue } }) => (
          <MultiCombobox
            input={{
              iconLeft: (
                <Circle size="h-full w-full p-2" className="bg-pink">
                  <StaffIcon fillColor="#fff" />
                </Circle>
              ),
            }}
            label={{
              label: 'Assignee',
              sublabel: 'Filter by task assignees',
            }}
            options={{
              options: usersOptions,
              itemIcon: (item) => (
                <Avatar src={item.photoUrl} alt="Profile photo" size="full" />
              ),
              isQueryable: {
                query(search: string) {
                  searchUsers({
                    search,
                    page: 1,
                  });
                },
                loading: isLoading,
              },
            }}
            defaultOptions={value}
            {...fieldValue}
          />
        )}
      />

      {hasTagsFieldFlag ? (
        <Controller
          control={control}
          name="tag_id"
          render={({ field: { value, ...fieldValue } }) => (
            <MultiCombobox
              input={{
                iconLeft: (
                  <Circle size="h-full w-full p-2" className="bg-pink">
                    <TagIcon fillColor="#fff" />
                  </Circle>
                ),
              }}
              label={{
                label: 'Tags',
                sublabel: 'Filter by tags',
              }}
              options={{
                options: tagOptions,
                itemIcon: () => (
                  <Circle size="h-full w-full p-2 sm:p-2.5" className="bg-pink">
                    <TagIcon fillColor="#fff" />
                  </Circle>
                ),
                pillIcon: () => (
                  <Circle size="h-full w-full p-1.5" className="bg-pink">
                    <TagIcon fillColor="#fff" />
                  </Circle>
                ),
                isQueryable: {
                  query(search: string) {
                    searchTags({
                      search,
                      page: 1,
                    });
                  },
                  loading: isLoadingTags,
                },
              }}
              defaultOptions={value}
              {...fieldValue}
            />
          )}
        />
      ) : null}

      <FormInputBase
        name="due_date"
        label="Due"
        error={
          errors['due_date']?.start?.message || errors['due_date']?.end?.message
        }
      >
        <div className="md:flex md:items-center md:justify-between md:gap-x-4">
          <Controller
            control={control}
            name="due_date.start"
            render={({ field: { value, onChange, onBlur } }) => (
              <DatePicker
                value={value ?? null}
                onChange={onChange}
                onBlur={onBlur}
                clearable
              />
            )}
          />

          <div className="text-center">&#45;</div>

          <Controller
            control={control}
            name="due_date.end"
            render={({ field: { value, onChange, onBlur } }) => (
              <DatePicker
                value={value ?? null}
                onChange={onChange}
                onBlur={onBlur}
                clearable
                minDate={
                  dueDateRangeStart ? new Date(dueDateRangeStart) : undefined
                }
              />
            )}
          />
        </div>
      </FormInputBase>

      {/* Important task only */}
      <div className="text-left">
        <Controller
          control={control}
          name="priority"
          render={({ field: { value, onChange } }) => (
            <TaskPriority
              label="Show important tasks only"
              priority={value ?? IPriorityLevelEnum.Medium}
              onChange={onChange}
            />
          )}
        />
      </div>
    </AppletFilterModalWrapper>
  );
};

export default TasksAppletFilterModal;
