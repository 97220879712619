import { ITaskAssignment } from '@tapestry/shared/graphql';
import { Maybe } from 'graphql/jsutils/Maybe';
import { AssignedUser } from '../../types';

type WithFirstName = {
  first_name: string;
  last_name?: string;
};

type WithLastName = {
  first_name?: string;
  last_name: string;
};

export type RenderableUser = {
  id: string;
  email?: Maybe<string>;
  isMe?: Maybe<boolean>;
  photo_url?: Maybe<string>;
} & (WithFirstName | WithLastName);

export const alphabeticallySort = (assignments: ITaskAssignment[]) => {
  return assignments.sort((a, b) => {
    // Sends blank first name to the bottom of the sort
    if (!a.assignee.first_name || !b.assignee.first_name) return 1;

    return a.assignee.first_name.localeCompare(b.assignee.first_name);
  });
};

export const getCurrentAssignees = (
  assignments: ITaskAssignment[]
): AssignedUser[] => {
  return assignments.map((assignment) => ({
    ...assignment.assignee,
    assignmentStatus: assignment.status,
  }));
};

export const getValidAssigments = (assignments: ITaskAssignment[]) => {
  return assignments.filter(
    (assignment) =>
      assignment.assignee.first_name || assignment.assignee.last_name
  );
};
